<template>
  <EmailAddressForm
    v-model:emailAddress="emailAddress"
    @remove="emit('remove')"
    @error="(v) => emit('error', v)"
  />
  <div
    class="text-text-secondary col-span-full mb-2 flex flex-col gap-2 text-sm"
  >
    <div class="flex w-full flex-col">
      <flux-checkbox-label>
        <flux-checkbox v-model:modelValue="useForAppointment" />
        {{ $t("patient.create.form.step.email_address.primary_appointments") }}
      </flux-checkbox-label>
      <flux-checkbox-label>
        <flux-checkbox v-model:modelValue="useForFinancial" />
        {{ $t("patient.create.form.step.email_address.primary_financial") }}
      </flux-checkbox-label>
      <flux-checkbox-label>
        <flux-checkbox v-model:modelValue="useForMedical" />
        {{ $t("patient.create.form.step.email_address.primary_medical") }}
      </flux-checkbox-label>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PatientEmailAddressForm } from "@/models/Forms";
import { computed } from "vue";
import EmailAddressForm from "./EmailAddressForm.vue";

const emailAddress = defineModel<PatientEmailAddressForm>("emailAddress", {
  required: true,
});

const useForAppointment = computed({
  get: () => emailAddress.value.use_for_appointment,
  set: (newVal) => (emailAddress.value.use_for_appointment = newVal),
});

const useForFinancial = computed({
  get: () => emailAddress.value.use_for_financial,
  set: (newVal) => (emailAddress.value.use_for_financial = newVal),
});

const useForMedical = computed({
  get: () => emailAddress.value.use_for_medical,
  set: (newVal) => (emailAddress.value.use_for_medical = newVal),
});

const emit = defineEmits<{
  (e: "error", v: boolean): void;
  (e: "remove"): void;
}>();
</script>
