<template>
  <label
    class="p-2"
    :class="[
      checked
        ? 'bg-blue-500 text-white hover:bg-blue-600'
        : 'text-text-secondary hover:bg-background-tertiary',
      disabled ? 'cursor-pointer opacity-50' : 'hover:bg-background-tertiary',
    ]"
  >
    <input
      v-model="value"
      type="radio"
      :class="[checked ? 'bg-red' : 'bg-background-primary']"
      :name="name"
      :value="optionValue"
      :disabled="disabled"
    />
    <slot></slot>
    <i
      v-if="icon"
      :class="[icon, iconPosition === 'right' ? 'order-last' : 'order-first']"
    ></i>
  </label>
</template>

<script
  setup
  lang="ts"
  generic="T extends (string | number | string[] | number[])[]"
>
import { computed } from "vue";

const modelValue = defineModel<T>("modelValue", { required: true });

const props = withDefaults(
  defineProps<{
    optionValue: string;
    disabled?: boolean;
    name?: string;
    icon?: string;
    iconPosition?: "left" | "right";
    collapse?: boolean;
  }>(),
  {
    disabled: false,
    name: "radioGroup",
    iconPosition: "left",
    collapse: false,
  },
);

const emit = defineEmits<{
  "update:modelValue": [T];
}>();

const value = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const checked = computed(() => {
  if (typeof modelValue.value === "boolean") {
    return modelValue.value;
  } else {
    if (props.optionValue === undefined) {
      throw new Error("cannot add undefined");
    }
    return modelValue.value.includes(props.optionValue);
  }
});
</script>
