<template>
  <flux-modal v-model:visible="visible" title="Mandaat toevoegen">
    <div class="flex flex-col gap-y-4">
      <flux-input-box label="Tekendatum" :required="true">
        <PlainDateInput v-model="signatureDate" :max="today" :required="true" />
      </flux-input-box>
      <flux-input-box label="IBAN" :required="true">
        <flux-input v-model="iban" :required="true" />
      </flux-input-box>
      <flux-input-box label="Referentie" :required="true">
        <div class="flex gap-4">
          <flux-input v-model="reference" :required="true" />
          <flux-button type="default" size="small" @click="generateReference">
            Referentie genereren
          </flux-button>
        </div>
      </flux-input-box>
    </div>
    <template #footer>
      <flux-button type="primary" @click="save" :disabled="!canSave">
        {{ $t("general.save") }}
      </flux-button>
      <flux-button type="default" @click="emit('cancel')">
        {{ $t("general.cancel") }}
      </flux-button>
    </template>
  </flux-modal>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import PlainDateInput from "../ui/PlainDateInput.vue";
import { useRecurringPaymentMandates } from "@/composables/recurringPaymentMandates";
import { handleErrors } from "@/libraries/utils/errorHandling";

const props = defineProps<{
  zisNumber: number;
}>();

const visible = defineModel<boolean>("visible", { required: true });

const emit = defineEmits(["cancel", "saved"]);

const { createRecurringPaymentMandate } = useRecurringPaymentMandates(
  computed(() => props.zisNumber),
);

const today = Temporal.Now.plainDateISO();
const signatureDate = ref<Temporal.PlainDate>(Temporal.Now.plainDateISO());
const iban = ref<string>("");
const reference = ref<string>("");

const canSave = computed(
  () =>
    signatureDate.value !== undefined &&
    iban.value !== "" &&
    reference.value !== "",
);

function generateReference() {
  reference.value = (Math.random() + 1).toString(36).substring(5);
}

async function save() {
  try {
    await createRecurringPaymentMandate(
      props.zisNumber,
      signatureDate.value,
      reference.value,
      iban.value,
    );
    emit("saved");
  } catch (e) {
    handleErrors({ error: e });
  }
}
</script>
