<template>
  <button
    :disabled="disabled"
    @keydown.enter="($event) => emit('keydown.enter', $event)"
    :class="[
      'border-border-secondary bg-background-primary text-text-primary inline-flex appearance-none items-center gap-1 rounded-sm border border-dashed px-2 py-1 text-sm dark:bg-transparent',
      disabled ? 'opacity-60' : 'hover:border-border-quaternary cursor-pointer',
    ]"
  >
    <i class="fal fa-plus"></i>
    <span>Filter</span>
  </button>
</template>

<script lang="ts" setup>
import { useHotkey } from "@/composables/hotkeyComposable";
import { ref } from "vue";

const props = defineProps<{
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "hotkeyDown"): void;
  (e: "keydown.enter", v: KeyboardEvent): void;
}>();

useHotkey(ref({ key: "F" }), () => {
  if (!props.disabled) {
    emit("hotkeyDown");
  }
});
</script>
