<template>
  <div class="flex flex-col">
    <div @click.stop="">
      <flux-input
        class="m-2"
        v-model:modelValue="searchString"
        ref="input"
        size="sm"
        @input="filterDropdownOptions(searchString)"
      />
    </div>
    <hr class="border-border-primary my-1 w-full" />
    <template v-if="filter.dropdownType === 'radio'">
      <RadioFilterButton
        class="text-sm"
        v-for="option in matchingOptions"
        :model-value="localOptions"
        :optionValue="option[filter.filterKey]?.toString()"
        :key="filter.label(option)"
        @update:model-value="
          updateFilter([option[filter.filterKey]?.toString()])
        "
      >
        {{ filter.label(option) }}
      </RadioFilterButton>
    </template>
    <div
      class="flex flex-col"
      v-else-if="filter.dropdownType === 'checkbox'"
      @click="handleClick($event)"
    >
      <div
        @click="selectAllOptions()"
        :class="['hover:bg-background-tertiary p-2 text-sm']"
      >
        <span> {{ $t("finance.invoice.filter.select_all") }} </span>
      </div>
      <flux-checkbox-label
        class="hover:bg-background-tertiary p-2 text-sm"
        v-for="option in matchingSelectedOptions"
        :key="option[filter.filterKey]?.toString()"
      >
        <flux-checkbox
          v-model:modelValue="localOptions"
          :optionValue="option[filter.filterKey]"
        />
        <HtmlRenderer v-if="option.icon" :element="option.icon" />
        {{ filter.label(option) }}
        <span
          class="text-text-tertiary ml-2 text-sm"
          v-if="option.count !== undefined"
        >
          {{ option.count }}
        </span>
      </flux-checkbox-label>
      <flux-checkbox-label
        v-for="option in matchingUnselectedOptions"
        :class="[
          'hover:bg-background-tertiary p-2 text-sm',
          option.count === 0 ? 'cursor-not-allowed' : '',
        ]"
        :key="option[filter.filterKey]?.toString()"
      >
        <flux-checkbox
          :model-value="localOptions"
          :disabled="option.count === 0"
          :optionValue="option[filter.filterKey]"
          @update:model-value="updateFilter($event)"
        />
        <HtmlRenderer v-if="option.icon" :element="option.icon" />
        <span :class="option.count === 0 ? 'text-text-quaternary' : ''">
          {{ filter.label(option) }}
        </span>
        <span
          class="text-text-tertiary ml-2 text-sm"
          v-if="option.count !== undefined"
        >
          {{ option.count }}
        </span>
      </flux-checkbox-label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref, toRef } from "vue";
import { FilterManager, OptionFilter } from "@/models/Filter/filter";
import { useFilterDropdownFilter } from "./OptionFilterDropdownFilterComposable";
import HtmlRenderer from "../../HtmlRenderer.vue";
import RadioFilterButton from "@/components/ui/Input/RadioFilterButton.vue";
import { $t } from "@/libraries/i18n";

const props = defineProps({
  filter: {
    required: true,
    type: Object as PropType<OptionFilter>,
  },
  filterManager: {
    required: true,
    type: Object as PropType<FilterManager>,
  },
});

const emit = defineEmits<{
  (e: "closed"): void;
}>();

const searchString = ref<string>();
const selectedOptions = computed(() =>
  props.filterManager.getSelectedOptions(props.filter.uuid),
);

const input = ref<HTMLElement>();
onMounted(() => input.value?.focus());

const localOptions = computed({
  get: () => selectedOptions.value,
  set: (newOptions) => updateFilter(newOptions),
});

const { filterDropdownOptions, matchingOptions } = useFilterDropdownFilter(
  toRef(props, "filter"),
);

const matchingSelectedOptions = computed(() => {
  return matchingOptions.value.filter((option) =>
    selectedOptions.value.includes(option[props.filter.filterKey]),
  );
});
const matchingUnselectedOptions = computed(() => {
  return matchingOptions.value.filter(
    (option) => !selectedOptions.value.includes(option[props.filter.filterKey]),
  );
});

function selectAllOptions() {
  let all: number[] = [];
  matchingOptions.value.forEach((option) => {
    all.push(option[props.filter.filterKey]);
  });
  updateFilter(all);
  props.filterManager.saveSelectedLogic(
    props.filter.uuid,
    props.filter.logicOptions?.at(0) ?? "OR",
  );
  localOptions.value = selectedOptions.value;
}

function updateFilter(
  newSelectedOptions: (string | number | string[] | number[])[] | boolean,
) {
  if (typeof newSelectedOptions === "boolean") {
    newSelectedOptions = [];
  }
  props.filterManager.saveSelectedOptions(
    props.filter.uuid,
    newSelectedOptions,
  );
  if (props.filter.dropdownType !== "checkbox") {
    emit("closed");
  }
}

const handleClickDisabled = ref(false);
function handleClick(e: MouseEvent) {
  if (e.target && "localName" in e.target && e.target.localName !== "input") {
    handleClickDisabled.value = true;
    setTimeout(() => (handleClickDisabled.value = false), 10);
    return;
  }
  if (handleClickDisabled.value) {
    return;
  }
  e.stopPropagation();
}
</script>
