import moment from "moment";
import { $t } from "./libraries/i18n";
import { formatDistanceToNowStrict } from "date-fns";
import nl from "date-fns/locale/nl";

export const datetimeFilter = (
  datetime: number | string | moment.Moment | Date | Temporal.PlainDateTime,
): string => {
  if (datetime instanceof Temporal.PlainDateTime) {
    return datetime.toLocaleString("nl-NL");
  }
  if (moment.isMoment(datetime)) {
    return datetime.format("DD-MM-YYYY HH:mm");
  }
  return moment(datetime).format("DD-MM-YYYY HH:mm");
};

export const timeRangeFilter = (
  start: number | string | moment.Moment | Date,
  end: number | string | moment.Moment | Date,
) => {
  return `${timeFilter(start)} - ${timeFilter(end)}`;
};

export const timeFilter = (time: number | string | moment.Moment | Date) => {
  return moment(time).format("HH:mm");
};

export const dateHumanFilter = (
  datetime: number | string | moment.Moment | Date | Temporal.PlainDate,
) => {
  if (datetime instanceof Temporal.PlainDate) {
    datetime = datetime.toString();
  }
  if (moment(datetime).isSame(moment(), "year")) {
    return moment(datetime).format("ddd D MMM");
  }
  return moment(datetime).format("ddd D MMM YYYY");
};

export const datetimeHumanFilter = (
  datetime: number | string | moment.Moment | Date | Temporal.PlainDateTime,
) => {
  if (datetime instanceof Temporal.PlainDateTime) {
    datetime = datetime.toString();
  }
  if (moment(datetime).isSame(moment(), "year")) {
    return moment(datetime).format("ddd D MMM [om] HH:mm");
  }
  return moment(datetime).format("ddd D MMM YYYY [om] HH:mm");
};

export const compactDatetimeHumanFilter = (
  datetime: number | string | moment.Moment | Date,
) => {
  return moment(datetime).format("dd D MMM [om] HH:mm");
};

export const dateFilter = (
  date:
    | number
    | string
    | Date
    | moment.Moment
    | Temporal.PlainDate
    | Temporal.PlainYearMonth,
) => {
  if (moment.isMoment(date)) {
    return date.format("DD-MM-YYYY");
  }
  if (date instanceof Temporal.PlainDate) {
    return date.toLocaleString("nl-NL", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }
  if (date instanceof Temporal.PlainYearMonth) {
    return (
      date.toLocaleString("nl-NL", {
        calendar: "iso8601",
        month: "long",
      }) +
      " " +
      date.toLocaleString("nl-NL", {
        calendar: "iso8601",
        year: "numeric",
      })
    );
  }
  return moment(date).format("DD-MM-YYYY");
};

export const durationHumanFilter = (duration: string | Temporal.Duration) => {
  if (typeof duration === "string") {
    duration = Temporal.Duration.from(duration);
  }
  const timeUnits: string[] = [];
  if (duration.years > 0) {
    timeUnits.push(duration.years + " " + $t("time.years", duration.years));
  }
  if (duration.months > 0) {
    timeUnits.push(duration.months + " " + $t("time.months", duration.years));
  }
  const days = 7 * duration.weeks + duration.days;
  const weeks = Math.floor(days / 7);
  const remainingDays = days % 7;
  if (weeks > 0) {
    timeUnits.push(weeks + " " + $t("time.weeks", weeks));
  }
  if (remainingDays > 0) {
    timeUnits.push(remainingDays + " " + $t("time.days", remainingDays));
  }
  return timeUnits.join(" " + $t("general.and").toLowerCase());
};

export function timeAsTextFilter(date: Temporal.PlainDateTime | Date | string) {
  if (date instanceof Temporal.PlainDateTime) {
    date = date.toString();
  }

  if (typeof date === "string") {
    date = new Date(date);
  }

  return formatDistanceToNowStrict(date, { locale: nl, addSuffix: false });
}

export const dateFilterWithDay = (
  datetime: number | string | moment.Moment | Date,
) => {
  if (moment.isMoment(datetime)) {
    return datetime.format("ddd DD-MM-YYYY");
  }
  return moment(datetime).format("ddd DD-MM-YYYY");
};

export const filesizeFilter = (bytes: number): string => {
  const prefixes = [
    { prefix: "T", size: 1e12 },
    { prefix: "G", size: 1e9 },
    { prefix: "M", size: 1e6 },
    { prefix: "k", size: 1e3 },
  ];

  const prefix = prefixes.find(({ size }) => {
    return size <= bytes;
  });

  if (prefix === undefined) {
    return bytes + " B";
  }

  const n = bytes / prefix.size;
  return n.toPrecision(3) + " " + prefix.prefix + "B";
};

export const money = (cents: number) =>
  "€ " +
  (cents / 100)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+,)/g, "$1.")
    .replace(",00", ",-");

export const moneyTabular = (cents: number) =>
  "€ " +
  (cents / 100)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+,)/g, "$1.");
