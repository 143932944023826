<template>
  <div
    class="border-border-secondary inline-flex items-center rounded-sm border"
  >
    <div class="p-1"><slot name="titleIcon"></slot>{{ filter.prefix }}</div>
    <FilterLogic
      :filter="filter"
      :filterManager="filterManager"
      :readonly="readonly"
    />
    <FilterDropdown
      v-if="filter.filterType === 'option' && filter.data.length > 0"
      :filter="filter"
      :filterManager="filterManager"
      :readonly="readonly"
    />
    <div class="px-1" v-if="filter.filterType === 'boolean'">
      {{ filter.subject.toString().toLowerCase() }}
    </div>
    <div
      class="p-1"
      v-if="filter.filterType === 'input'"
      :class="readonly ? '' : 'hover:bg-background-tertiary cursor-pointer p-1'"
      @click="modalVisible = true"
    >
      {{ filterStates.input[filter.uuid].text }}
    </div>
    <div
      class="p-1"
      v-if="filter.filterType === 'range'"
      :class="readonly ? '' : 'hover:bg-background-tertiary cursor-pointer p-1'"
      @click="modalVisible = true"
    >
      {{ formatIfDate(filterStates.range[filter.uuid].rangeStart) }}
      →
      {{ formatIfDate(filterStates.range[filter.uuid].rangeEnd) }}
    </div>
    <RangeFilterModal
      v-if="filter.filterType === 'range' && !readonly"
      :filter="filter"
      :filterManager="filterManager"
      :modalVisible="modalVisible"
      @save="modalVisible = false"
      @cancel="modalVisible = false"
    />
    <InputFilterModal
      v-if="filter.filterType === 'input' && !readonly"
      :filter="filter"
      :filterManager="filterManager"
      :modalVisible="modalVisible"
      @save="modalVisible = false"
      @cancel="modalVisible = false"
    />
    <slot name=""></slot>
    <div
      class="hover:bg-background-tertiary cursor-pointer rounded-r-[calc(0.25rem-1px)] p-1"
      v-if="!readonly"
      @click="removeFilter(props.filter.uuid)"
    >
      <i class="fal fa-times" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from "vue";
import { Filter, FilterManager } from "@/models/Filter/filter";
import FilterLogic from "./FilterLogic.vue";
import FilterDropdown from "./OptionFilter/OptionFilterDropdown.vue";
import InputFilterModal from "./InputFilter/InputFilterModal.vue";
import RangeFilterModal from "./RangeFilter/RangeFilterModal.vue";
import { dateFilter } from "@/filters";

const props = defineProps({
  filter: {
    required: true,
    type: Object as PropType<Filter>,
  },
  filterManager: {
    required: true,
    type: Object as PropType<FilterManager>,
  },
  readonly: {
    default: false,
    type: Boolean,
  },
});

const { filterStates, removeFilter } = props.filterManager;

const modalVisible = ref(false);

function formatIfDate(text: string) {
  if (!("isDate" in props.filter) || !props.filter.isDate) {
    return text;
  }
  return dateFilter(text);
}
</script>
