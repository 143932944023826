<template>
  <div
    @click="(ev) => $emit('click', ev)"
    :class="[
      'hover:text-text-link-hover flex cursor-pointer border-l-2 py-3 pl-4 select-none',
      active
        ? 'hover:text-text-link-hover border-blue-500 text-blue-500'
        : 'border-transparent',
    ]"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup() {},
});
</script>
