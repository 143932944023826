<template>
  <flux-card class="mx-auto max-w-prose" shadow="never" :class="{ hidden }">
    <div class="relative flex gap-4">
      <div class="absolute top-0 right-0">
        <ClickableIcon icon="fal fa-times" @click="hide" :hideTooltip="true" />
      </div>
      <div>
        <svg
          class="w-12 fill-red-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            class=""
            d="M569.52 440L329.58 24c-18.44-32-64.69-32-83.16 0L6.48 440c-18.42 31.94 4.64 72 41.57 72h479.89c36.87 0 60.06-40 41.58-72zM288 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 297.52 352h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 265.68 192h44.64a16 16 0 0 1 15.92 17.59z"
          />
          <path
            class="opacity-20"
            d="M310.32 192h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 278.48 352h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 310.32 192zM288 384a32 32 0 1 0 32 32 32 32 0 0 0-32-32z"
          />
        </svg>
      </div>
      <div class="flex flex-col gap-1">
        <h1 class="text-base font-semibold">Update je browser</h1>
        <div class="text-text-tertiary text-sm">
          De browser die je gebruikt is verouderd. Om Flux goed te kunnen
          gebruiken, raden we je aan om een nieuwe browser te installeren. Een
          oude browser kan gevaarlijk zijn voor je privacy en veiligheid.
          Bovendien werken sommige functies van Flux niet goed in oude browsers.
        </div>
        <div>
          <ul class="m-0 list-none p-0">
            <li class="mx-0 p-0">
              <a href="https://www.google.com/chrome/">Google Chrome</a>
            </li>
            <li class="mx-0 p-0">
              <a href="https://www.microsoft.com/nl-nl/edge">Microsoft Edge</a>
            </li>
            <li class="mx-0 p-0">
              <a href="https://www.mozilla.org/nl/firefox/new/"
                >Mozilla Firefox</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </flux-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ClickableIcon from "./ClickableIcon.vue";

const hidden = ref(false);

function hide() {
  hidden.value = true;
}
</script>
