<template>
  <button
    type="button"
    @click="emit('click', $event)"
    :class="[
      'group text-text-primary z-0 flex w-full items-center gap-x-1 rounded-sm bg-transparent p-0 text-left font-sans text-base ring-slate-400/70 outline-hidden transition duration-75 focus:ring-3',
      'hover:bg-background-tertiary hover:cursor-pointer',
      'focus: focus:bg-background-tertiary',
      ' ',
    ]"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts" setup>
const emit = defineEmits(["click"]);
</script>
