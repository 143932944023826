<template>
  <div class="contents">
    <div>
      <div class="text-base font-semibold">
        {{ props.title }}
      </div>
      <span class="text-text-tertiary text-xs" v-if="!$slots.description">
        {{ props.description }}
      </span>
      <slot class="text-text-tertiary text-xs" v-else name="description"></slot>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title: string;
  description?: string;
}>();
</script>
