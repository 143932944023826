<template>
  <flux-dropdown :autoClose="true" :stopPropagation="true" direction="right">
    <template #button="slotProps">
      <div
        class="hover:bg-blueGray-200 focus-within:bg-blueGray-200 focus-within:text-text-primary hover:text-text-primary-hover inline-flex items-center justify-around rounded-full ring-blue-500/50 outline-hidden focus-within:ring-3 focus:ring-3"
        tabindex="0"
        :id="slotProps.uuid"
        @keydown.enter="
          ($event) => {
            slotProps.handleKeyDown($event);
            $event.preventDefault();
          }
        "
        @click="
          ($event) => {
            slotProps.handleClick($event);
            $event.preventDefault();
          }
        "
      >
        <div
          class="font-regular inline-flex flex-row justify-between gap-1 rounded-full bg-linear-to-br px-2.5 py-1 text-xs shadow-xs"
          :class="
            isExpired()
              ? 'text-text-tertiary from-gray-100 to-gray-300 dark:from-gray-900 dark:to-gray-700'
              : cssClass(task.status)
          "
        >
          <div class="w-max">
            {{
              isExpired()
                ? $t("task.status.expired")
                : $t("task.status." + task.status)
            }}
          </div>
          <div
            v-if="isExpired() ? 'fa-clock' : icon(task.status) !== undefined"
          >
            <i
              class="far w-3 text-center"
              :class="isExpired() ? 'fa-clock' : icon(task.status)"
            ></i>
          </div>
        </div>
      </div>
    </template>
    <template #default="slotProps">
      <flux-dropdown-item
        v-for="status in taskStatuses"
        :key="status"
        @click="
          (e) => {
            slotProps.handleClick(e);
            changeStatus(status);
          }
        "
      >
        <div class="flex w-full flex-row justify-start gap-2 px-1 text-right">
          <div v-if="icon(status) !== undefined">
            <i class="far w-3 text-center" :class="icon(status)"></i>
          </div>
          <div class="w-max">
            {{ $t("task.status." + status) }}
          </div>
        </div>
      </flux-dropdown-item>
    </template>
  </flux-dropdown>
</template>

<script setup lang="ts">
import { useMyTasks } from "@/composables/myTasks";
import { $t } from "@/libraries/i18n";
import { apiClient } from "@/libraries/utils/axios";
import { useNotify } from "@/composables/notify";
import { Task, TaskStatus, taskStatuses } from "@/models/Task";
import { computed, PropType } from "vue";

const { notify } = useNotify();

const props = defineProps({
  task: {
    type: Object as PropType<Task>,
    required: true,
  },
});

const { invalidate: invalidateMyTasks } = useMyTasks();

const emit = defineEmits<{
  (e: "update"): void;
}>();

const dueDateIsInPast = computed(
  () =>
    Temporal.PlainDate.compare(
      props.task.due_date,
      Temporal.Now.plainDateISO(),
    ) === -1,
);

function isExpired() {
  if (props.task.status !== "to_do") {
    return false;
  }
  return dueDateIsInPast.value;
}

function icon(status: TaskStatus) {
  switch (status) {
    case "to_do":
      return "fa-circle";

    case "doing":
      return "fa-pencil";

    case "cancelled":
      return "fa-times";

    case "done":
      return "fa-check";
  }
}

function cssClass(status: TaskStatus) {
  switch (status) {
    case "doing": {
      if (dueDateIsInPast.value) {
        return "from-orange-100 to-orange-300 text-orange-600 dark:from-orange-900 dark:to-orange-700 dark:text-orange-100";
      }
      return "from-yellow-100 to-yellow-300 text-yellow-600 dark:from-yellow-900 dark:to-yellow-700 dark:text-yellow-100";
    }
    case "to_do": {
      return "from-blue-200 to-blue-400 text-blue-800 dark:from-blue-800 dark:to-blue-600 dark:text-blue-100";
    }
    case "cancelled":
      return "from-red-500 to-red-700 text-red-100 dark:from-red-700 dark:to-red-400 dark:text-red-100";

    case "done":
      return "from-green-100 to-green-300 text-green-600 dark:from-green-900 dark:to-green-700 dark:text-green-100";
  }
}

async function changeStatus(status: TaskStatus) {
  const taskObject = {
    assignee_id: props.task.assignee_id,
    patient_zis_number: props.task.patient_zis_number,
    status: status,
    title: props.task.title,
    due_date: props.task.due_date.toString(),
    healthcare_provider_id: props.task.healthcare_provider_id,
    description: props.task.description,
  };
  try {
    await apiClient.put(`/tasks/${props.task.id}`, taskObject);

    invalidateMyTasks();
    emit("update");
    notify({
      message: $t("task.update.success"),
      type: "success",
    });
  } catch {
    notify({
      message: $t("task.update.error"),
      type: "error",
    });
  }
}
</script>
