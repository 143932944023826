<template>
  <div class="grid grid-cols-[max-content_1fr] items-center gap-1">
    <div>Question</div>
    <div><flux-input v-model="item.text"></flux-input></div>
    <div>min Value</div>
    <div><flux-input v-model="minValue"></flux-input></div>
    <div>max Value</div>
    <div><flux-input v-model="maxValue"></flux-input></div>
  </div>
</template>

<script lang="ts" setup>
import {
  DecimalItem,
  EXTENSION,
  IntegerItem,
} from "@/libraries/questionnaires/item";
import { computed } from "vue";

const props = defineProps<{
  item: IntegerItem | DecimalItem;
  leftLabel?: string;
  rightLabel?: string;
}>();

/**
 * For behaviour of the radio group, see
 * https://www.w3.org/TR/wai-aria-practices/examples/radio/radio-1/radio-1.html
 */

const bounds = computed((): [number, number] => {
  if (!Array.isArray(props.item.extension)) {
    return [-Infinity, Infinity];
  }
  const minValue =
    props.item.extension?.find((ex) => ex.url === EXTENSION.MIN_VALUE)
      ?.valueDecimal ?? -Infinity;

  const maxValue =
    props.item.extension?.find((ex) => ex.url === EXTENSION.MAX_VALUE)
      ?.valueDecimal ?? Infinity;

  return [minValue, maxValue];
});

const minValue = computed({
  get: () => bounds.value[0],
  set: (val: number) => {
    if (!props.item.extension) {
      props.item.extension = [];
    }

    if (props.item.extension.find((ex) => ex.url === EXTENSION.MIN_VALUE)) {
      props.item.extension.find(
        (ex) => ex.url === EXTENSION.MIN_VALUE,
      )!.valueDecimal = val;
    } else {
      props.item.extension.push({
        url: EXTENSION.MIN_VALUE,
        valueDecimal: val,
      });
    }
  },
});

const maxValue = computed({
  get: () => bounds.value[1],
  set: (val: number) => {
    if (!props.item.extension) {
      props.item.extension = [];
    }

    if (props.item.extension.find((ex) => ex.url === EXTENSION.MAX_VALUE)) {
      props.item.extension.find(
        (ex) => ex.url === EXTENSION.MAX_VALUE,
      )!.valueDecimal = val;
    } else {
      props.item.extension.push({
        url: EXTENSION.MAX_VALUE,
        valueDecimal: val,
      });
    }
  },
});
</script>
