<template>
  <div>
    <EmptyState
      v-if="!patientLabels || patientLabels.length === 0"
      :sub-text="
        canCreateNewLabels ? 'Maak de eerste patiëntlabel aan.' : undefined
      "
      text="Geen patiëntlabels gevonden"
    >
      <template #button>
        <flux-button
          v-if="canCreateNewLabels"
          @click="router.push('/admin/patientlabels')"
          type="primary"
          icon="fal fa-plus"
        >
          Maak een label
        </flux-button>
      </template>
    </EmptyState>
    <div class="flex flex-col gap-4" v-else>
      <flux-input-box label="Selecteer labels">
        <div
          class="grid grid-cols-[max-content_auto] justify-start gap-x-2 gap-y-2"
        >
          <label class="contents" v-for="label in patientLabels">
            <flux-checkbox
              v-model:modelValue="formValue"
              :optionValue="label.id"
              :label="label.name"
            />
            <ItemLabel :name="label.name" :color="label.color" />
          </label>
        </div>
      </flux-input-box>

      <flux-button-group class="justify-end">
        <flux-submit-button @click="addLabel" type="primary">{{
          $t("general.save")
        }}</flux-submit-button>
      </flux-button-group>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import ItemLabel from "@/components/ItemLabel.vue";
import { apiClient } from "@/libraries/utils/axios";
import { $t } from "@/libraries/i18n";
import { handleErrors } from "@/libraries/utils/errorHandling";
import { useNotify } from "@/composables/notify";
import {
  PatientWithoutMedicalData,
  usePatientWithoutMedicalData,
} from "@/composables/patientWithoutMedicalData";
import { usePatientLabels } from "@/composables/patientLabels";
import EmptyState from "@/components/ui/EmptyState.vue";
import router from "@/libraries/router";
import { getUser } from "@/libraries/plugins/getUser";

const { notify } = useNotify();

const props = defineProps<{
  patient: PatientWithoutMedicalData;
}>();

const emit = defineEmits(["saved"]);

const formValue = ref<number[]>([]);

const { data: patientLabels } = usePatientLabels();

onMounted(() => {
  getPatientLabels();
});

const { invalidatePatient } = usePatientWithoutMedicalData(
  computed(() => props.patient.zis_number),
);

const canCreateNewLabels = computed(() =>
  getUser().roles.some(({ name }) => name === "admin" || name === "owner"),
);

const getPatientLabels = async () => {
  if (props.patient.labels) {
    let labelId: number[] = [];
    props.patient.labels?.forEach((label) => {
      labelId.push(label.id);
    });
    formValue.value = labelId;
  }
};

const addLabel = async () => {
  try {
    const res = await apiClient.put(
      "/patients/:zis_number/labels",
      {
        label_id: formValue.value,
      },
      {
        params: {
          zis_number: props.patient.zis_number,
        },
      },
    );

    invalidatePatient(props.patient.zis_number);
    notify({
      message: "Labels aangepast",
      type: "success",
    });
  } catch (error) {
    handleErrors({
      error,
    });
  } finally {
    emit("saved");
  }
};
</script>
