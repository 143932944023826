<template>
  <flux-callout @action="clickAction" :type="type">
    <template #icon>
      <i
        :class="['text-xl', type === 'info' ? 'text-text-tertiary' : '', icon]"
      ></i>
    </template>
    <template v-if="$slots.action" #action>
      <slot name="action"></slot>
    </template>
    <slot></slot>
  </flux-callout>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    type?: "error" | "info" | "warning";
    icon?: string;
  }>(),
  { type: "error" },
);

const emit = defineEmits<{
  (e: "action"): void;
}>();

function clickAction() {
  emit("action");
}

const icon = computed(() => {
  if (props.icon) {
    return props.icon;
  }
  if (props.type === "error" || props.type === "warning") {
    return "fas fa-exclamation-triangle";
  }
  return "fas fa-question-circle";
});
</script>
