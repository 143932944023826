<template>
  <div :class="groupType == 'contents' ? 'contents' : 'my-4'">
    <label
      class="mr-2 self-center"
      v-if="item.text != null"
      :for="'b_' + uuid"
      >{{ item.text }}</label
    >
    <div v-if="readonly">{{ response[item.linkId] }}</div>
    <div class="inline-block w-64" v-else>
      <datalist :id="'choices' + uuid">
        <option
          v-for="n in item.answerOption"
          :value="n.valueString || n"
          :key="uuid + (n.valueString || n)"
        >
          {{ n.valueString || n }}
        </option>
      </datalist>
      <input
        class="animate border-border-quaternary bg-background-primary text-text-primary block w-full appearance-none rounded-sm border px-4 py-2 text-sm outline-hidden duration-75 hover:border-gray-500 focus:border-blue-600 focus:ring-3"
        v-model="response[item.linkId]"
        :id="'b_' + uuid"
        :list="'choices' + uuid"
        type="text"
        :placeholder="item.placeholder"
      />
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends string">
import { OpenChoiceItem } from "@/libraries/questionnaires/item";

const uuid = crypto.randomUUID();

defineProps<{
  item: OpenChoiceItem<T>;
  response: any;
  readonly: boolean;
  groupType?: string | number | string[];
}>();
</script>
