<template>
  <div class="flex flex-col gap-y-2 pl-4">
    <p class="border-border-secondary border-b-[1px] pb-2">Patiënt</p>
    <AsyncPatientLink
      v-if="conversation.patient.patientZisNumber"
      :zis-number="conversation.patient.patientZisNumber"
    />
    <div class="grid grid-cols-[150px_1fr] gap-y-2 text-xs" v-else>
      <div class="font-semibold">Naam</div>

      <div>{{ conversation.patient.zdPatient?.name[0]?.text }}</div>

      <div class="font-semibold">Adres</div>
      <div>{{ conversation.patient.zdPatient?.address[0]?.text }}</div>
      <template
        v-for="(contact, index) in conversation.patient.zdPatient?.telecom"
        :key="`patient_contacts_${index}`"
      >
        <template v-if="contact?.system === 'email'">
          <div class="font-semibold">Emailadres</div>
          <div>{{ contact.value }}</div>
        </template>
        <template v-else-if="contact?.system === 'phone'">
          <div class="font-semibold">Telefoonnummer</div>
          <div>{{ contact.value }}</div>
        </template>
        <template v-else-if="contact">
          <div class="font-semibold">{{ contact.system }}</div>
          <div>{{ contact.value }}</div>
        </template>
      </template>
    </div>
    <div class="border-border-secondary border-b-[1px] pb-2">
      Verwijzend arts
    </div>
    <div class="grid grid-cols-[150px_1fr] gap-y-2 text-xs">
      <div class="font-semibold">Naam</div>
      <div>{{ conversation.sender.practitioner?.name[0]?.text }}</div>
      <div class="w-32 font-semibold">Praktijknaam</div>
      <div>{{ conversation.sender.organization?.name }}</div>
      <template v-if="organisationAgb">
        <div class="w-32 font-semibold">Praktijk AGB</div>
        <div>{{ organisationAgb.value }}</div>
      </template>
    </div>

    <div class="border-border-secondary border-b-[1px] pb-2">Berichten</div>
    <div class="flex flex-col gap-y-2">
      <template
        v-for="message in sortedMessages"
        :key="`conversation_message_${message.id}`"
      >
        <div>{{ $t(`medical_converstion.message_type.${message.type}`) }}</div>
        <div class="flex gap-x-2 overflow-x-auto">
          <template
            v-for="(attachment, index) in attachmentsForMessage(message)"
            :key="`attachment_${message.id}_${index}`"
          >
            <div
              class="border-border-secondary flex w-fit cursor-pointer flex-col items-center rounded-md border-[1px] p-2 text-center"
              @click="openModal(attachment.attachment)"
            >
              <FileIconComponent
                class="text-2xl sm:text-2xl"
                :filetype="attachment.attachment.contentType"
              ></FileIconComponent>
              <div class="text-sm">
                {{
                  attachment.attachment.title ??
                  $t(`medical_converstion.message_type.${message.type}`)
                }}
              </div>
              <div
                class="text-xs"
                v-if="message.message.entry[0].resource.date"
              >
                {{
                  format(
                    message.message.entry[0].resource.date,
                    "dd-MM-yyyy HH:mm",
                  )
                }}
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <flux-modal
      class="w-full"
      v-model:visible="showModal"
      :title="displayAttachment?.title ?? 'Bijlage'"
      @close="closeModal"
    >
      <object
        class="h-[80vh] w-[80vw]"
        v-if="displayAttachment"
        type="application/pdf"
        :data="'data:application/pdf;base64,' + displayAttachment?.data"
      ></object>
    </flux-modal>
  </div>
</template>
<script lang="ts" setup>
import FileIconComponent from "@/components/FileIcon.vue";
import { clone } from "@/libraries/utils/clone";
import {
  MedicalConversation,
  MedicalConversationMessage,
  MedicalConversationMessageAttachment,
} from "@/queries/medicalConversations/medicalConversations";
import { format } from "date-fns";
import { computed, ref } from "vue";
import AsyncPatientLink from "../ui/AsyncPatientLink.vue";

const props = defineProps<{
  conversation: MedicalConversation;
}>();

const displayAttachment = ref<MedicalConversationMessageAttachment>();
const showModal = ref(false);

function openModal(attachment: MedicalConversationMessageAttachment) {
  displayAttachment.value = attachment;
  showModal.value = true;
}

function closeModal() {
  showModal.value = false;
  displayAttachment.value = undefined;
}

function attachmentsForMessage(message: MedicalConversationMessage) {
  return (
    message.message.entry?.find(
      (entry) => entry.resource.resourceType === "DocumentReference",
    )?.resource.content ?? []
  );
}

const organisationAgb = computed(() => {
  return props.conversation.sender.organization?.identifier.find(
    (id) => id.system === "http://fhir.nl/fhir/NamingSystem/agb-z",
  );
});

const sortedMessages = computed(() =>
  clone(props.conversation.messages).sort((a, b) => {
    if (!a.message.entry[0].resource.date || !b.message.entry[0].resource.date)
      return 0;
    const dateA = a.message.entry[0].resource.date;
    const dateB = b.message.entry[0].resource.date;

    if (dateA < dateB) return 1;
    if (dateA > dateB) return -1;
    return 0;
  }),
);
</script>
