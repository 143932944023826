<template>
  <div
    ref="root"
    @drop="handleDrop"
    @dragover="dragOver"
    @dragleave="reset"
    @dragend="reset"
  >
    <div
      class="group flex min-h-[6rem] items-center justify-center rounded-sm border border-dashed p-6 duration-200"
      :class="getClassName(state)"
      @click="showFileBrowser"
    >
      <input
        class="hidden"
        type="file"
        @input="onInput"
        :multiple="!uploadOne"
        ref="input"
        :accept="allowedTypes.join(',')"
      />
      <div class="pointer-events-none flex w-full flex-col items-center">
        <div>
          <i
            class="fal fa-file-upload ease transform text-4xl duration-200"
            v-if="state !== 'error'"
            :class="[
              state === 'dragging' ? '-translate-y-1' : '',
              state === 'initial' ? 'group-hover:-translate-y-0.5' : '',
            ]"
          ></i>
        </div>
        <div class="relative mt-2 min-h-12 w-full text-center">
          <transition
            enter-active-class="transition transform left-0 right-0 duration-200 ease"
            enter-from-class="absolute opacity-0 translate-y-1"
            enter-to-class="opacity-100"
            leave-active-class="absolute transition left-0 right-0 transform duration-200 ease"
            leave-from-class="opacity-100 "
            leave-to-class="-translate-y-1 opacity-0"
          >
            <div
              class="bg-teal-40 min-h-12"
              v-if="state === 'initial' && files.length === 0"
            >
              <span class="md:hidden">
                {{ $t("file_drop.state.initial_mobile") }}
              </span>
              <span class="hidden md:inline">
                {{ $t("file_drop.state.initial") }}
                <br />
                {{ $t("file_drop.state.initial2") }}
              </span>
            </div>
            <div
              class="bg-orange-40 min-h-12"
              v-else-if="state === 'dragging' && files.length === 0"
            >
              {{ $t("file_drop.state.dragging", draggingCount) }}
            </div>
            <div v-else>
              {{ $t("file_drop.state.dropped", files.length) }}
            </div>
          </transition>
          <div
            class="mt-4 items-center"
            v-if="files.length > 0"
            :class="
              state === 'dragging'
                ? 'pointer-events-none'
                : 'pointer-events-auto'
            "
          >
            <div
              class="hides-icons border-border-secondary mb-2 flex flex-col items-center overflow-hidden rounded-sm border bg-slate-200 font-mono shadow-xs"
              v-for="file in sortedFiles"
              :key="file.name"
            >
              <div class="flex items-center place-self-stretch p-2">
                <FileIconComponent
                  class="text-xl sm:text-2xl"
                  :filetype="file.name"
                ></FileIconComponent>
                <div class="ml-2 grow text-left text-xs sm:text-sm">
                  {{ file.name }}
                </div>
                <div class="ml-4">
                  <clickable-icon-group>
                    <clickable-icon
                      v-if="!isUploading"
                      icon="fa-times"
                      :hideTooltip="true"
                      content=" "
                      @click="removeFile($event, file)"
                    ></clickable-icon>
                  </clickable-icon-group>
                </div>
              </div>
              <div
                class="self-start text-sm transition-all duration-75 ease-linear sm:text-base"
                :style="{
                  height: '0.25rem',
                  width:
                    Math.max(
                      file.name in progress ? progress[file.name] : 0,
                      5,
                    ) + '%',
                }"
                :class="{
                  'bg-blue-400': file.name in progress,
                }"
              ></div>
            </div>
            <div>
              <slot name="uploadBtn"></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center" v-if="state === 'error'">
        <i class="fas fa-times-circle text-4xl"></i>
        <span class="ml-2" v-html="error"></span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import {
  extensionToMimeType,
  fileIsCorrectSize,
  getFileMimeType,
} from "@/libraries/utils/files";
import FileIconComponent from "./FileIcon.vue";
import ClickableIconGroup from "./ClickableIconGroup.vue";
import { computed, ref, watch } from "vue";
import { $t } from "@/libraries/i18n";

type State = "initial" | "dragging" | "dropped" | "error";

const props = withDefaults(
  defineProps<{
    modelValue?: File[];
    allowedFiles?: string[];
    uploadOne?: boolean;
    maxSize?: number | undefined;
    progress?: { [file: string]: number };
    disabled?: boolean;
    dataUri?: string | null;
  }>(),
  {
    allowedFiles: () => [],
    uploadOne: false,
    progress: () => ({}),
    disabled: false,
  },
);

function onInput(e: Event) {
  const target = e.target as HTMLInputElement;
  if (target.files) {
    fileInput(target.files);
  }
}

const emit = defineEmits<{
  change: [File[]];
  "update:modelValue": [File[]];
}>();

watch(
  () => props.dataUri,
  (newDataUri) => {
    if (newDataUri === null) {
      state.value = files.value.length === 0 ? "initial" : "dropped";
    }
  },
);

const isUploading = computed(() => {
  return files.value.some((file) => file.name in props.progress);
});

function getClassName(state: State): string {
  if (state === "dragging") {
    return "border-blue-600 outline outline-blue-400/50 outline-offset-2 text-text-primary";
  }

  if (state === "dropped") {
    if (isUploading.value) {
      return "border-blue-600 border-solid! bg-slate-50/70 /50";
    }

    return "border-blue-600 outline border-solid! outline-blue-400 bg-slate-50/70 /50";
  }

  if (state === "error") {
    return "border-red-600 bg-red-100 hover:border-red-600";
  }

  if (state === "initial") {
    return "border-border-primary text-text-secondary  cursor-pointer outline outline-blue-400/0 outline-offset-2 hover:border-border-quaternary  ";
  }

  throw new Error("Unknown state " + state);
}

const files = ref<File[]>(props.modelValue ?? []);

const state = ref<State>("initial");

const error = ref<string | null>(null);

const sortedFiles = computed<File[]>(() => {
  return files.value.toSorted((a, b) => a.name.localeCompare(b.name));
});

const allowedTypes = computed(() => {
  return props.allowedFiles.map(extensionToMimeType);
});

const root = ref<HTMLDivElement | null>(null);

function reset(ev: any) {
  if (
    !root.value?.isSameNode(ev.target) &&
    !root.value?.children[0].isSameNode(ev.target)
  ) {
    return;
  }

  state.value = files.value.length === 0 ? "initial" : "dropped";
}

function removeFile(event: Event, file: File) {
  event.stopPropagation();
  files.value = files.value.filter((f) => f.name !== file.name);
  if (files.value.length == 0) {
    state.value = "initial";
  }
}

const input = ref<HTMLInputElement | null>(null);
function showFileBrowser() {
  if (state.value != "initial") {
    return;
  }

  input.value?.click();
}

function fileInput(fileList: FileList) {
  Array.from(fileList).forEach(async (file) => {
    addFile(file);
  });
  state.value = "dropped";
}

async function handleDrop(e: DragEvent) {
  e.preventDefault();
  if (!e.dataTransfer) {
    return;
  }

  if (props.disabled) {
    state.value = "initial";
    return;
  }

  if (props.uploadOne && e.dataTransfer.files.length + files.value.length > 1) {
    state.value = "error";
    error.value = $t("file_drop.errors.should_upload_one") as string;
    return;
  }

  const fileList = Array.from(e.dataTransfer.files);
  if (!handleValidExtensionsCheck(fileList)) {
    return;
  }

  for (const file of fileList) {
    if (
      props.maxSize !== undefined &&
      !(await fileIsCorrectSize(file, props.maxSize))
    ) {
      error.value = $t("file_drop.errors.file_too_large") as string;
      state.value = "error";
      return;
    }
  }

  fileList.forEach((file) => addFile(file));
  state.value = "dropped";
}

async function addFile(file: File) {
  if (props.uploadOne) {
    files.value = [];
  }

  if (files.value.some((f) => f.name == file.name)) {
    return;
  }

  files.value.push(file);
  emit("update:modelValue", files.value);
}

function handleValidExtensionsCheck(files: File[]): boolean {
  const filesWithInvalidExtensions = files.filter(
    (file) => !extensionIsAllowed(file),
  );
  if (filesWithInvalidExtensions.length > 0) {
    const file = filesWithInvalidExtensions[0];
    state.value = "error";
    const type = getFileMimeType(file);
    error.value = $t("file_drop.errors.type_is_not_allowed", {
      type,
    }) as string;
    return false;
  }

  return true;
}

const draggingCount = ref(1);

function dragOver(e: DragEvent) {
  e.preventDefault();
  const dataTransfer = e.dataTransfer;
  if (dataTransfer == null) {
    return;
  }

  if (props.disabled) {
    state.value = "initial";
    return;
  }

  const files = Array.from(dataTransfer.files);
  if (!handleValidExtensionsCheck(files)) {
    return;
  }

  draggingCount.value = dataTransfer.items.length;

  state.value = "dragging";
}

function extensionIsAllowed(file: File) {
  if (props.allowedFiles.length == 0) {
    return true;
  }

  return props.allowedFiles
    .map((s) => s.toLowerCase())
    .includes(getExtension(file).toLowerCase());
}

function getExtension(file: File): string {
  const split = file.name.split(".");
  if (split.length == 0) {
    return "";
  }
  return split[split.length - 1];
}
</script>
