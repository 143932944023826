<template>
  <div class="flex items-center gap-2 text-sm whitespace-nowrap select-none">
    <div
      class="flex grow flex-wrap items-center gap-2 text-sm whitespace-nowrap select-none"
    >
      <FilterWrapper
        v-for="filter in activeFilters"
        :filter="filter"
        :filterManager="filterManager"
        :key="filter.uuid"
      />
      <FilterSelector :filterManager="filterManager" />
      <button
        class="border-border-secondary bg-background-primary text-text-primary hover:border-border-quaternary inline-flex cursor-pointer appearance-none items-center gap-1 rounded-sm border border-dashed px-2 py-1 text-sm"
        v-if="activeFilters.length !== 0"
        @click="filterManager.removeAllFilters()"
      >
        <i class="fal fa-times"></i>
        <span>{{ $t("general.delete") }}</span>
      </button>
    </div>
    <div
      class="text-text-quaternary flex-1"
      v-if="hasFilterLogic && activeFilters.length > 1"
    >
      <span>moet voldoen aan </span>
      <span
        class="bold text-text-primary cursor-pointer"
        @click="toggleLogic()"
        >{{ filterLogic === "AND" ? "alle " : "een van de " }}</span
      >
      <span>filters</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { FilterManager } from "@/models/Filter/filter";
import FilterSelector from "./FilterSelector.vue";
import FilterWrapper from "./FilterWrapper.vue";
import { $t } from "@/libraries/i18n";

const props = defineProps({
  filterManager: {
    required: true,
    type: Object as PropType<FilterManager>,
  },
  hasFilterLogic: {
    default: true,
    type: Boolean,
  },
});

const { activeFilters, filterLogic } = props.filterManager;

function toggleLogic() {
  if (filterLogic.value === "AND") {
    filterLogic.value = "OR";
    return;
  }
  filterLogic.value = "AND";
}
</script>
