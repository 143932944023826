<template>
  <div>
    <TypeAheadComponent
      v-model="modelValue"
      :data="data ?? []"
      prefix="AGB"
      placeholder="Zoek op naam"
      :regexPattern="/^\d{8}$|^$/"
      @select="handleSelect"
      @error="emit('error', $event)"
    >
      <template #results="{ data, activeIndex }">
        <ResultList :listData="data" :activeIndex="activeIndex">
          <template #top>
            <li
              class="text-text-primary mt-2 mb-2 px-3 text-xs font-medium uppercase"
            >
              Huisartsen
            </li>
          </template>
          <template #entry="{ entry, active }">
            <a
              :class="[
                'group box-border flex w-full cursor-pointer flex-col items-start px-3 py-2 text-sm transition duration-75',
                active
                  ? 'bg-background-tertiary text-text-secondary'
                  : 'text-text-secondary hover:bg-background-tertiary active:text-text-primary-active',
              ]"
            >
              <span class="text-sm">{{ formatName(entry) }}</span>
              <span class="text-text-quaternary text-xs">{{
                entry.agb_code
              }}</span>
            </a>
          </template>
          <template #bottom>
            <li
              class="border-border-primary bg-background-tertiary text-text-tertiary -mb-1 flex items-baseline gap-2 border-t px-3 py-2 text-xs"
            >
              <div class="grow">
                Type

                <kbd
                  class="bg-background-primary inline-flex h-4 w-4 items-center justify-around rounded-sm border text-center font-mono"
                  ><i class="fal fa-sort-alt"
                /></kbd>
                om te navigeren en
                <kbd
                  class="bg-background-primary inline-flex h-4 w-4 items-center justify-around rounded-sm border text-center font-mono"
                  >⏎</kbd
                >
                selecteren.
              </div>
              <div class="whitespace-nowrap">
                <a
                  class="hidden xl:inline"
                  href="https://www.vektis.nl/agb-register/zoeken"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fas fa-external-link-square"></i> Zoek met Vektis</a
                >
              </div>
            </li>
          </template>
        </ResultList>
      </template>
    </TypeAheadComponent>
  </div>
</template>

<script lang="ts" setup>
import { fullNameInitials } from "@/models/Person";
import TypeAheadComponent from "@/components/ui/Input/TypeAhead.vue";
import { useDebouncedValue } from "@/composables/debouncedValue";
import { useVektisHealthcareProfessionalAgbSearch } from "@/composables/vektisHealthcareProfessionalAgbSearch";
import { VektisHealthcareProfessionalAgb } from "@/models/VektisHealthcareProfessionalAgb";
import ResultList from "../ResultList.vue";

const emit = defineEmits<{
  (event: "select", person: VektisHealthcareProfessionalAgb): void;
  (event: "error", v: boolean): void;
}>();

const modelValue = defineModel<string | undefined>("modelValue", {
  required: true,
});

const { data } = useVektisHealthcareProfessionalAgbSearch(
  useDebouncedValue(modelValue, 150),
);

const formatName = (result: VektisHealthcareProfessionalAgb) =>
  fullNameInitials(result);

function handleSelect(person: VektisHealthcareProfessionalAgb) {
  emit("select", person);
  modelValue.value = person.agb_code;
}
</script>
