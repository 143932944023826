<template>
  <div class="relative">
    <flux-input
      v-model:modelValue="modelValue"
      :icon="icon"
      @focus="onFocus($event)"
      @blur="onBlur"
      @keydown="keydown"
      @error="emit('error', $event)"
      :placeholder="placeholder || $t('general.search')"
      :prefix="prefix"
      :disabled="disabled"
      :pattern="regexPattern ?? pattern"
      ref="typeAheadRef"
    >
    </flux-input>
    <transition
      enter-active-class="transition duration-75 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="transform opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div
        class="border-border-secondary bg-background-primary absolute z-30 mt-2 box-border w-full origin-top overflow-hidden rounded-sm border shadow-lg"
        v-show="
          listData &&
          (listData.length > 0 || combinedLength > 0) &&
          showTypeAhead
        "
      >
        <slot name="results" :data="listData" :activeIndex="activeIndex">
        </slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup generic="T">
import { debounce } from "debounce";
import { useRouter } from "vue-router";
import { Ref, computed, provide, ref, useTemplateRef } from "vue";
// When using multiple resultList components, the onSelect method from resultList needs to be used.
// The onEnter method needs to be used in the typeahead component.
const router = useRouter();

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    icon?: string;
    prefix?: string;
    disabled?: boolean;
    typeahead?: (() => Promise<T[]>) | (() => T[]);
    data?: T[];
    debounceTimeout?: number;
    defaultData?: T[];
    autoSelect?: boolean;
    pattern?: string;
    regexPattern?: RegExp;
    combinedLength?: number;
  }>(),
  {
    debounceTimeout: 300,
    defaultData: (): T[] => [],
    autoSelect: false,
    combinedLength: 0,
  },
);

const modelValue = defineModel<string | undefined>();
const typeAheadRef = useTemplateRef("typeAheadRef");

const emit = defineEmits<{
  (event: "select", value: T): void;
  (event: "submit"): void;
  (event: "enter", value: number): void;
  (event: "error", value: boolean): void;
}>();

provide("onSelectFromTypeAhead", onSelect);

const activeIndex = ref<number | undefined>(undefined);

const internalData = ref<T[]>([]) as Ref<T[]>;

const showTypeAhead = ref(false);

const debouncedTypeahead = debounce(async () => {
  if (props.typeahead) {
    internalData.value = await props.typeahead();
  }
}, props.debounceTimeout);

const listData = computed(() => {
  if (props.data !== undefined) {
    return props.data;
  }

  return modelValue.value === "" ? props.defaultData : internalData.value;
});

function onEnter() {
  if (activeIndex.value !== undefined) {
    if (props.combinedLength) {
      emit("enter", activeIndex.value);
    } else {
      emit("select", listData.value[activeIndex.value]);
    }
  }
}

function onSelect(selected: T) {
  activeIndex.value = undefined;
  showTypeAhead.value = false;
  if (props.combinedLength === 0) {
    emit("select", selected);
  }
}

const length = computed(() =>
  props.combinedLength ? props.combinedLength : listData.value.length,
);

function keydown(ev: KeyboardEvent) {
  if (ev.key == "ArrowUp") {
    ev.preventDefault();
    up();
    return;
  }

  if (ev.key == "ArrowDown") {
    ev.preventDefault();
    down();
    return;
  }

  if (ev.key == "Escape") {
    showTypeAhead.value = false;
  }

  if (ev.key === "Enter") {
    if (activeIndex.value !== undefined && activeIndex.value < length.value) {
      onEnter();
    } else if (activeIndex.value === undefined && length.value === 1) {
      activeIndex.value = 0;
      onEnter();
    } else {
      emit("submit");
      showTypeAhead.value = false;
    }
    return;
  }

  debouncedTypeahead();
}

function up() {
  if (activeIndex.value == undefined) {
    activeIndex.value = length.value - 1;
  } else if (activeIndex.value == 0) {
    activeIndex.value = undefined;
  } else {
    activeIndex.value = (activeIndex.value - 1) % length.value;
  }
}

function down() {
  if (activeIndex.value == undefined) {
    activeIndex.value = 0;
    return;
  }
  if (activeIndex.value == length.value - 1) {
    activeIndex.value = undefined;
    return;
  }
  activeIndex.value = (activeIndex.value + 1) % length.value;
  return;
}

/**
 * Handles blurring of typeahead. Because Vue won't push the route if the
 * visibility is hidden (which happens when you click the link), it is done
 * manually in this method.
 */
function onBlur(event: FocusEvent) {
  window.setTimeout(() => {
    if (event.type !== "blur") {
      return;
    }
    showTypeAhead.value = false;
    if (
      !event.relatedTarget ||
      (event.relatedTarget as HTMLElement).tagName !== "A"
    ) {
      return;
    }
    if (
      (event.relatedTarget as HTMLAnchorElement).classList.contains(
        "typeahead__item",
      )
    ) {
      router.push(
        (event.relatedTarget as HTMLAnchorElement).href.split("#")[1],
      );
    }
  }, 16);
}

function onFocus(event: Event) {
  showTypeAhead.value = true;
  if (event.target instanceof HTMLInputElement && props.autoSelect) {
    event.target.select();
  }
}

defineExpose({
  focus: () => typeAheadRef.value?.focus(),
});
</script>
