import { createStore } from "vuex";
import { dissoc } from "../utils";
import { State, UserFromSync } from "./interfaces";
import calendar from "./Calendar";
import { apiClient } from "../utils/axios";
import { TypedAxiosResponse } from "restyped-axios";
import { FluxApi } from "../../apis/flux";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import Calendar from "./Calendar";
import { getActiveHealthcareProfessionalType } from "@/models/User";

export const store = createStore({
  state:
    ("hot" in import.meta && import.meta.hot && import.meta.hot.data.user) ||
    ({
      token: undefined,
      user: undefined,
    } as State),
  actions: {
    sync({ commit }) {
      return apiClient.get("sync").then((response) => commit("sync", response));
    },
  },
  mutations: {
    sync(state: State, response: TypedAxiosResponse<FluxApi, "/sync", "GET">) {
      if (response.data.user) {
        state.user = dissoc(
          "healthcare_provider_id",
          response.data.user,
        ) as UserFromSync;
        if ("hot" in import.meta && import.meta.hot) {
          import.meta.hot.data.user = response.data.user;
        }
        state.user.healthcare_professional_type =
          getActiveHealthcareProfessionalType(response.data.user);
        state.roles = response.data.roles;
        state.apps = response.data.apps;
        Sentry.setUser({
          id: response.data.user.uuid,
          hcp_id: response.data.user.healthcare_provider.id,
        });
      }
      if (!response.data.authenticated) {
        state.token = undefined;
        Sentry.setUser({
          user_id: undefined,
          hcp_id: undefined,
        });
      }
    },
  },
  getters: {
    hcp_users: (state) => {
      if (!state.user) {
        return [];
      }

      return state.user.healthcare_provider.users || [];
    },
    hcp: (state) => {
      if (!state.user) {
        return undefined;
      }
      return state.user.healthcare_provider;
    },
  },
  modules: {
    calendar,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(["./Calendar"], () => {
    const calendar = Calendar; //require("./Calendar").default;

    // When the module is updated, retrieve the new data and update the store
    store.hotUpdate({
      modules: {
        calendar: calendar,
      },
    });
  });
}

export default store;
export const pinia = createPinia();
