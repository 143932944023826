<template>
  <div class="mx-auto w-full max-w-4xl">
    <div v-flux-loading="loading">
      <div class="flex items-center justify-between">
        <h1 class="m-0 text-2xl font-medium">
          {{ $t("search.title") }} "<strong>{{ $route.query.query }}</strong
          >"
        </h1>
        <flux-button
          class="hidden sm:inline-flex"
          @click="router.push('/patient/create')"
          size="small"
          type="text"
          icon="fal fa-plus"
          >{{ $t("patient.create.button") }}</flux-button
        >
      </div>
      <template v-if="patientsData === undefined">
        <div></div>
      </template>
      <template v-else-if="patientsData.length > 0">
        <div class="block sm:hidden">
          <ContentButtonComponent
            class="min-h-[4rem] p-2"
            v-for="patient in patientsData"
            :key="patient.zis_number"
            @click="router.push(`/patient/${patient.zis_number}`)"
            tabindex="0"
          >
            <div>
              <PatientPhoto :zisNumber="patient.zis_number" />
            </div>
            <div class="ml-2 flex flex-col">
              <span class="text-left text-base">{{ fullName(patient) }}</span>
              <div
                class="text-text-quaternary group-hover:text-text-primary-hover text-sm"
              >
                <GenderIconComponent
                  v-if="patient.gender"
                  :gender="patient.gender"
                ></GenderIconComponent>
                ·
                <span>{{ patient.zis_number }}</span>
                ·
                <span v-if="patient.date_of_birth">{{
                  dateFilter(patient.date_of_birth)
                }}</span>
              </div>
            </div>
          </ContentButtonComponent>
        </div>
        <flux-table class="hidden sm:block" :data="patientsData">
          <flux-table-column
            :label="$t('general.photo')"
            prop="photo"
            tdClass="w-12"
          >
            <template #row="row: PatientSearchResult">
              <PatientPhoto size="42px" :zisNumber="row.zis_number" />
            </template>
          </flux-table-column>
          <flux-table-column primary :label="$t('general.name')" prop="name">
            <template #row="row: PatientSearchResult">
              <router-link :to="'/patient/' + row.zis_number">
                {{ fullName(row) }}
              </router-link>
            </template>
          </flux-table-column>
          <flux-table-column
            prop="date_of_birth"
            :label="$t('general.date_of_birth')"
            thClass="w-24 sm:w-32"
          >
            <template #row="row: PatientSearchResult">
              <span v-if="row.date_of_birth">
                {{ dateFilter(row.date_of_birth) }}
              </span>
              <span class="unknown" v-else>
                {{ $t("general.unknown") }}
              </span>
            </template>
          </flux-table-column>
          <flux-table-column
            prop="gender"
            :label="$t('general.gender')"
            thClass="w-8 sm:w-12 min-w-min"
          >
            <template #row="row: PatientSearchResult">
              <GenderIconComponent
                v-if="row.gender"
                :gender="row.gender"
              ></GenderIconComponent>
            </template>
          </flux-table-column>
          <flux-table-column
            prop="zis_number"
            :label="$t('general.zis_number')"
            thClass="w-16 sm:w-24"
          />
          <flux-table-column prop="" label="Acties" thClass="w-16 sm:w-24">
            <template #row="row: PatientSearchResult">
              <flux-button
                v-if="row.inactive_at"
                size="mini"
                type="text"
                @click="changeInactiveState(row)"
                >Activeer
              </flux-button>
              <flux-button
                v-if="!row.inactive_at"
                size="mini"
                type="text"
                @click="changeInactiveState(row)"
                >Deactiveer
              </flux-button>
            </template>
          </flux-table-column>
        </flux-table>
      </template>
      <div v-else>
        <div style="text-align: center">
          <img
            src="../assets/images/people_search.svg"
            style="width: 100%; max-width: 300px"
          />
        </div>
        <div style="text-align: center; margin-top: 14px">
          {{ $t("search.patient_not_found_with_name") }} "<strong>{{
            $route.query.query
          }}</strong
          >".
        </div>
        <div style="text-align: center; margin-top: 14px">
          <flux-button
            @click="router.push('/patient/create')"
            icon="fal fa-plus"
            type="primary"
            >{{ $t("patient.create.button") }}</flux-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import GenderIconComponent from "./GenderIcon.vue";
import ContentButtonComponent from "./ui/Input/ContentButton.vue";
import { PatientRepositoryUsingApi } from "@/libraries/repositories/patientRepositoryUsingApi";
import { PatientSearchResult } from "@/libraries/repositories/patientRepository";
import { usePatientSearch } from "@/composables/patientSearch";
import { fullName } from "../models/Person";
import { dateFilter } from "../filters";
import { useRoute } from "vue-router";
import { useNotify } from "@/composables/notify";
import PatientPhoto from "./Patient/PatientPhoto.vue";

const { notify } = useNotify();
const router = useRouter();

const route = useRoute();

const query = computed(() => route.query.query?.toString());

const { data: patientsData, isLoading, invalidate } = usePatientSearch(query);

const loading = computed(() => isLoading.value || inactivating.value);

const inactivating = ref<boolean>(false);

const repository = ref<PatientRepositoryUsingApi>(
  new PatientRepositoryUsingApi(),
);

async function changeInactiveState(row: PatientSearchResult) {
  inactivating.value = true;
  if (!row.inactive_at) {
    await repository.value.deactivatePatient(row.zis_number);
    notify({
      type: "success",
      message: "Patient is gedeactiveerd",
    });
  }
  if (row.inactive_at) {
    await repository.value.activatePatient(row.zis_number);
    notify({
      type: "success",
      message: "Patient is geactiveerd",
    });
  }
  if (query.value) {
    invalidate(query.value);
  }
  inactivating.value = false;
}
</script>
