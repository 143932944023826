<template>
  <DropdownWrapper :disabled="readonly" :autoClose="true" direction="right">
    <template #button>
      <div
        class="group flex items-center p-1"
        :class="readonly ? '' : 'hover:bg-background-tertiary cursor-pointer'"
      >
        <div class="flex min-w-min flex-nowrap" v-if="selectedOptions">
          <template v-for="option in selectedOptionsWithIcon.slice(0, 3)">
            <HtmlRenderer
              v-if="option && option.icon"
              :element="option.icon"
              :class="[
                'w-3 transition-[width] last:mr-3',
                selectedOptionsWithIcon.length > 1 ? 'group-hover:w-4' : '',
              ]"
            />
          </template>
        </div>
        {{ buttonLabel }}
      </div>
    </template>
    <FilterDropdownContents :filter="filter" :filterManager="filterManager" />
  </DropdownWrapper>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import DropdownWrapper from "@/components/ui/Dropdown/DropdownWrapper.vue";
import { FilterManager, OptionFilter } from "@/models/Filter/filter";
import FilterDropdownContents from "./OptionFilterDropdownContents.vue";
import HtmlRenderer from "../../HtmlRenderer.vue";

const props = defineProps({
  filter: {
    required: true,
    type: Object as PropType<OptionFilter>,
  },
  filterManager: {
    required: true,
    type: Object as PropType<FilterManager>,
  },
  readonly: {
    default: false,
    type: Boolean,
  },
});

const selectedOptions = computed(() => {
  const options = props.filterManager.getSelectedOptions(props.filter.uuid);
  return options.map((option) =>
    props.filter.data.find(
      (filterData: any) => filterData[props.filter.filterKey] === option,
    ),
  );
});

const selectedOptionsWithIcon = computed(() =>
  selectedOptions.value.filter((option) => option?.icon),
);

const buttonLabel = computed(() => {
  if (selectedOptions.value.length !== 1) {
    return (
      selectedOptions.value.length +
      " " +
      (props.filter.suffix ?? props.filter.subject)
    );
  }
  return props.filter.label(selectedOptions.value[0]);
});
</script>
