<template>
  <button
    v-if="!url"
    type="button"
    @click="(e) => emit('click', e)"
    :disabled="disabled"
    :class="[
      'text-text-primary relative flex w-full items-center px-4 py-2 align-middle font-sans text-sm ring-blue-500/50 outline-hidden focus:z-50 focus:ring-3',
      'group transition duration-75',
      disabled ? 'opacity-40' : 'cursor-pointer',
      type === 'default'
        ? 'bg-background-primary text-text-secondary ' +
          (disabled
            ? ''
            : 'hover:bg-background-tertiary hover:text-text-primary-hover')
        : '',
      type === 'danger'
        ? 'bg-red-50 text-red-900 dark:bg-red-800 dark:text-red-100' +
          (disabled ? '' : 'hover:text-text-primary-hover hover:bg-red-100')
        : '',
    ]"
  >
    <slot name="icon">
      <i
        class="mr-1 -ml-1 w-5 text-center"
        v-if="icon != null"
        :class="[
          icon,
          type === 'default'
            ? 'text-text-quaternary ' +
              (disabled
                ? ''
                : 'group-hover: group-hover:text-text-primary-hover')
            : 'text-red-400 ' +
              (disabled ? '' : 'group-hover:text-text-primary-hover'),
        ]"
      ></i>
    </slot>
    <slot></slot>
    <div class="-mr-2 ml-4 flex w-full justify-end" v-if="hotkey && hotkey.key">
      <HotkeyDisplay :hotkey="hotkey" :withBackground="false" />
    </div>
  </button>
  <router-link
    v-else
    :to="url"
    type="button"
    @click.exact="emit('click', $event)"
    :disabled="disabled"
    :class="[
      'text-text-primary relative flex w-full items-center px-4 py-2 align-middle font-sans text-sm ring-blue-500/50 outline-hidden focus:z-50 focus:ring-3',

      'group transition duration-75',
      disabled ? 'opacity-40' : 'cursor-pointer',
      type === 'default'
        ? 'bg-background-primary text-text-secondary ' +
          (disabled
            ? ''
            : 'hover:bg-background-tertiary hover:text-text-primary-hover')
        : '',
      type === 'danger'
        ? 'bg-red-50 text-red-700 ' +
          (disabled ? '' : 'hover:text-text-primary-hover hover:bg-red-100')
        : '',
    ]"
  >
    <slot name="icon">
      <i
        class="mr-1 -ml-1 w-5 text-center"
        v-if="icon != null"
        :class="[
          icon,
          type === 'default'
            ? 'text-text-quaternary ' +
              (disabled ? '' : 'group-hover:text-text-primary-hover')
            : 'text-red-400 ' +
              (disabled ? '' : 'group-hover:text-text-primary-hover'),
        ]"
      ></i>
    </slot>
    <slot></slot>
  </router-link>
</template>

<script lang="ts" setup>
import { useHotkey } from "@/composables/hotkeyComposable";
import { Hotkey } from "@/models/Hotkey";
import { PropType, ref } from "vue";
import HotkeyDisplay from "@/components/ui/HotkeyDisplay.vue";

const props = defineProps({
  type: {
    default: "default",
    type: String as PropType<"default" | "danger">,
  },
  icon: {
    type: String,
  },
  url: {
    type: String,
  },
  disabled: {
    type: Boolean,
  },
  hotkey: {
    type: Object as PropType<Hotkey>,
  },
});

const emit = defineEmits<{
  (e: "click", event: MouseEvent): void;
  (e: "hotkeyDown"): void;
}>();

useHotkey(ref(props.hotkey), () => emit("hotkeyDown"));
</script>
