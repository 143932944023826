<template>
  <div class="contents">
    <button :class="cssClass" @click="addConstantNumber()">Constant</button>
    <button :class="cssClass" @click="addReferenceNumber()">Reference</button>
    <button :class="cssClass" @click="addProduct()">Product</button>
    <button :class="cssClass" @click="addDivide()">Divide</button>
    <button :class="cssClass" @click="addSum()">Sum</button>
    <button :class="cssClass" @click="addCount()">Count</button>
    <button :class="cssClass" @click="addCountNotNull()">Count not null</button>
    <button :class="cssClass" @click="addCountNull()">Count null</button>
    <button :class="cssClass" @click="addMaximum()">Maximum</button>
    <button :class="cssClass" @click="addMinimum()">Minimum</button>
    <button :class="cssClass" @click="addSubtract()">Subtract</button>
    <button :class="cssClass" @click="addIfElse()">IfElse</button>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(["item"]);

const cssClass =
  "py-2 rounded-sm hover:bg-indigo-600 bg-indigo-100 text-indigo-600 hover:text-text-primary-hover cursor-pointer";

function addConstantNumber() {
  emit("item", {
    type: "constant_number",
    value: 1,
  });
}
function addReferenceNumber() {
  emit("item", {
    type: "reference_number",
    linkId: "",
  });
}
function addProduct() {
  emit("item", {
    type: "product",
    item: [],
  });
}
function addSum() {
  emit("item", {
    type: "sum",
    item: [],
  });
}
function addCount() {
  emit("item", {
    type: "count",
    item: null,
  });
}
function addCountNotNull() {
  emit("item", {
    type: "count_not_null",
    pattern: "",
  });
}
function addCountNull() {
  emit("item", {
    type: "count_null",
    pattern: "",
  });
}
function addSubtract() {
  emit("item", {
    type: "subtract",
    left: undefined,
    right: undefined,
  });
}

function addDivide() {
  emit("item", {
    type: "divide",
    left: undefined,
    right: undefined,
  });
}

function addFind() {
  emit("item", {
    type: "find",
    pattern: "",
  });
}

function addIfElse() {
  emit("item", {
    type: "ifelse",
    operator: "=",
    exp: undefined,
    true: undefined,
    false: undefined,
    left: undefined,
    right: undefined,
  });
}

function addMaximum() {
  emit("item", {
    type: "maximum",
    item: [],
  });
}

function addMinimum() {
  emit("item", {
    type: "minimum",
    item: [],
  });
}
</script>
