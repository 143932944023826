<template>
  <flux-modal
    @close="close"
    :title="$t('photo_upload.title')"
    :visible="modelValue"
  >
    <div class="mt-6" v-flux-loading="loading">
      <div v-show="!method">
        <flux-button-group>
          <flux-button @click="startCamera">
            <input
              class="hidden"
              ref="mobileCameraInput"
              type="file"
              capture="user"
              accept="image/*"
              @change="onMobileImageChange($event)"
            />
            <div class="w-6 text-center">
              <i class="fas fa-camera text-xl"></i>
            </div>
            <div class="text-lg whitespace-nowrap">
              {{ $t("photo_upload.webcam") }}
            </div>
          </flux-button>
          <flux-button @click="startupUpload">
            <div class="w-6 text-center">
              <i class="fas fa-file-upload text-xl"></i>
            </div>
            <div class="text-lg whitespace-nowrap">
              <div>
                {{ $t("photo_upload.upload") }}
              </div>
            </div>
          </flux-button>
        </flux-button-group>
      </div>
      <div class="flex flex-col items-center gap-8" v-show="method == 'webcam'">
        <div
          class="h-64 w-64 overflow-hidden rounded-full"
          v-flux-loading="loadingWebcam"
        >
          <video
            class="h-64 w-64 bg-gray-300 bg-cover"
            ref="video"
            style="transform: scale(calc(-4 / 3), calc(4 / 3))"
          >
            Video stream not available.
          </video>
        </div>
        <flux-button-group>
          <flux-button type="default" @click="close()">{{
            $t("general.cancel")
          }}</flux-button>
          <flux-button type="primary" @click="takephoto">{{
            $t("photo_upload.take_photo")
          }}</flux-button>
        </flux-button-group>
      </div>
      <div class="w-96 min-w-96" v-show="method == 'upload'">
        <div v-if="droppedFiles.length == 0">
          <FileDropComponent
            v-model="droppedFiles"
            :key="'filedrop' + key"
            :allowedFiles="['jpeg', 'jpg', 'png']"
            :uploadOne="true"
          ></FileDropComponent>
        </div>
        <div class="flex flex-col items-center gap-8" v-else>
          <div class="h-64 w-64 overflow-hidden rounded-full">
            <img class="h-full w-full object-cover" :src="dataURL" />
          </div>
          <flux-button-group>
            <flux-button type="default" @click="close()">{{
              $t("general.cancel")
            }}</flux-button>
            <flux-button
              type="primary"
              @click="handleUpload(droppedFiles[0])"
              >{{ $t("general.upload") }}</flux-button
            >
          </flux-button-group>
        </div>
      </div>
    </div>
  </flux-modal>
</template>

<script setup lang="ts">
import { apiClient } from "@/libraries/utils/axios";
import { isPatient, PatientApi } from "../models/Patient";
import { User } from "../models/User";
import FileDropComponent from "@/components/FileDrop.vue";
import { readFile } from "@/libraries/utils/files";
import { onUnmounted, ref, watch } from "vue";
import { $t } from "@/libraries/i18n";
import { useNotify } from "@/composables/notify";
import { Patient } from "@/composables/patient";

const { notify } = useNotify();

const props = defineProps<{
  modelValue: boolean;
  person: Patient | PatientApi | User | undefined;
}>();

const emit = defineEmits<{
  "update:modelValue": [boolean];
}>();

const method = ref<"webcam" | "upload" | false>(false);

const stream = ref<MediaStream | null>(null);

const droppedFiles = ref<File[]>([]);

const key = ref<number>(Math.random());

const loading = ref(false);
const loadingWebcam = ref(false);

watch(
  () => props.modelValue,
  () => {
    if (!props.modelValue) {
      stopStream();
      droppedFiles.value = [];
      key.value++;
    }
    method.value = false;
  },
);

watch(droppedFiles, () => {
  if (droppedFiles.value.length > 0) {
    showPhoto(droppedFiles.value[0]);
  }
});

async function onMobileImageChange(e: Event) {
  if (!e.target) {
    return;
  }
  if (!("files" in e.target) || e.target.files) {
    return;
  }
  const images: File[] = e.target.files as File[];
  if (images.length === 0) {
    return;
  }
  const dataUri = await toBase64(images[0]);
  if (typeof dataUri === "string") {
    uploadToApi(dataUri);
  }
}

function toBase64(file: File): Promise<string | null | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const dataURL = ref<string | undefined>(undefined);

async function showPhoto(file: File) {
  dataURL.value = await readFile(file, "DataURL");
}

function close() {
  emit("update:modelValue", false);
  stopCamera();
}

function stopStream() {
  stream.value?.getTracks().forEach((track) => track.stop());
}

onUnmounted(() => {
  stopStream();
});

function startupUpload() {
  method.value = "upload";
}

function dragOver(e: Event) {
  e.preventDefault();
}

async function handleUpload(file: File) {
  const dataURL = await readFile(file, "DataURL");
  uploadToApi(dataURL);
}

function startCamera() {
  if (window.innerWidth < 768) {
    startupSelfieCamera();
  } else {
    startupWebcam();
  }
}

const mobileCameraInput = ref<HTMLInputElement | null>(null);

function startupSelfieCamera() {
  mobileCameraInput.value?.click();
}

const video = ref<HTMLVideoElement | null>(null);
function startupWebcam() {
  loadingWebcam.value = true;
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: false })
    .then((stream) => {
      if (video.value === null) {
        return;
      }
      method.value = "webcam";
      video.value.srcObject = stream;
      video.value.onplaying = () => {
        loadingWebcam.value = false;
      };
      video.value.play();
    })
    .catch((err) => {
      loadingWebcam.value = false;
      throw err;
    });
}

function takephoto() {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context || !video.value) {
    return;
  }
  canvas.width = 640;
  canvas.height = 480;
  context.drawImage(video.value, 0, 0, 640, 480);
  const dataUri = canvas.toDataURL("image/png");
  uploadToApi(dataUri);
}

function uploadToApi(dataUri: string) {
  if (!props.person) {
    return;
  }

  loading.value = true;
  const body = {
    data_uri: dataUri,
    user_id: undefined as any,
    patient_zis_number: undefined as any,
  };
  if (isPatient(props.person)) {
    body.patient_zis_number = props.person.zis_number;
  } else {
    body.user_id = props.person.id;
  }

  return apiClient
    .post("/photos", body)
    .then((response) => {
      notify({
        type: "success",
        message: $t("photo_upload.success") as string,
      });
      emit("update:modelValue", false);
      return response;
    })
    .finally(() => {
      stopCamera();
      loading.value = false;
    });
}

function stopCamera() {
  if (video.value?.srcObject) {
    for (const track of (<MediaStream>video.value.srcObject).getTracks()) {
      track.stop();
    }
    video.value.srcObject = null;
  }
}
</script>
