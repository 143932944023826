<template>
  <flux-radio-button-group>
    <flux-radio-button
      v-model:modelValue="newDefaultInvoiceSetting.default_invoice_type"
      :optionValue="null"
    >
      Automatisch
    </flux-radio-button>
    <flux-radio-button
      v-model:modelValue="newDefaultInvoiceSetting.default_invoice_type"
      optionValue="patient.pm304"
    >
      Voor patiënt
    </flux-radio-button>
    <flux-radio-button
      v-model:modelValue="newDefaultInvoiceSetting.default_invoice_type"
      optionValue="insurer.partial.pm304"
    >
      Voor verzekeraar
    </flux-radio-button>
  </flux-radio-button-group>
  <div class="my-4 text-sm">Voor afspraken in de periode</div>
  <div class="flex w-full items-end gap-2">
    <flux-input-box label="Vanaf">
      <flux-input
        v-model:modelValue="newDefaultInvoiceSetting.start_date"
        type="date"
      />
    </flux-input-box>
    <flux-input-box label="Tot en met">
      <flux-input
        v-model:modelValue="newDefaultInvoiceSetting.end_date"
        type="date"
      />
    </flux-input-box>
    <flux-button
      class="ml-2"
      @click="addDefaultInvoice"
      type="primary"
      size="small"
    >
      {{ $t("general.add") }}
    </flux-button>
  </div>
  <flux-long-form-divider class="mt-4 mb-2" />
  <div class="my-2 text-sm">Bestaande regels:</div>
  <div
    class="mb-2 flex w-full items-center justify-between"
    v-for="(setting, idx) in invoiceSettings.default_invoice_types"
    :key="idx"
  >
    <div>
      <template v-if="!setting.default_invoice_type"> Automatisch </template>
      <template v-else>
        {{
          setting.default_invoice_type === "patient.pm304"
            ? "Voor patiënt"
            : "Voor verzekeraar"
        }}
      </template>
      <span class="text-sm" v-if="setting.start_date">
        vanaf {{ dateFilter(setting.start_date) }}
      </span>
      <span class="text-sm" v-if="setting.end_date">
        tot en met {{ dateFilter(setting.end_date) }}
      </span>
    </div>
    <ClickableIcon icon="fa-trash-alt" @click="removeDefaultInvoice(idx)" />
  </div>
</template>

<script lang="ts" setup>
import ClickableIcon from "@/components/ClickableIcon.vue";
import { dateFilter } from "@/filters";
import { ReferralInvoiceSettings } from "@/libraries/repositories/Referral/ReferralRepository";
import { clone } from "@/libraries/utils/clone";
import { readonly, ref } from "vue";

const invoiceSettings = defineModel<ReferralInvoiceSettings>({
  required: true,
});

const emit = defineEmits(["defaultInvoiceTypesChanged"]);

const emptyDefaultInvoiceSetting = readonly({
  default_invoice_type: null,
  start_date: undefined,
  end_date: undefined,
});
const newDefaultInvoiceSetting = ref<
  NonNullable<ReferralInvoiceSettings["default_invoice_types"]>[number]
>(clone(emptyDefaultInvoiceSetting));

function addDefaultInvoice() {
  invoiceSettings.value.default_invoice_types?.push(
    newDefaultInvoiceSetting.value,
  );
  newDefaultInvoiceSetting.value = clone(emptyDefaultInvoiceSetting);
  emit("defaultInvoiceTypesChanged");
}

function removeDefaultInvoice(index: number) {
  invoiceSettings.value.default_invoice_types?.splice(index, 1);
  emit("defaultInvoiceTypesChanged");
}
</script>
