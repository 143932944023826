<template>
  <Tooltip>
    <template #content>
      <PatientTooltipContent :zisNumber="patient.zis_number" />
    </template>
    <router-link
      v-if="hasPermission('view-patient')"
      @mouseover="prefetch"
      :title="fullName(patient)"
      :to="'/patient/' + patient.zis_number"
    >
      <span class="inline-flex items-baseline gap-0.5">
        {{ patientName }}
        <ItemLabel
          v-for="label in patient.labels ?? []"
          :key="label.id"
          :color="label.color"
          :name="label.name"
          size="small"
        />
      </span>
    </router-link>
    <span class="text-text-primary inline-flex items-baseline gap-0.5" v-else>
      {{ patientName }}
      <ItemLabel
        v-for="label in patient.labels ?? []"
        :key="label.id"
        :color="label.color"
        :name="label.name"
        size="small"
      />
    </span>
  </Tooltip>
</template>

<script lang="ts" setup>
import {
  FormatName,
  fullName,
  fullNameInitials,
  Person,
} from "@/models/Person";
import { computed } from "vue";
import { usePatientPrefetch } from "@/composables/patient";
import { Label } from "@/models/Label";
import ItemLabel from "@/components/ItemLabel.vue";
import PatientTooltipContent from "./TooltipContent/PatientTooltipContent.vue";
import Tooltip from "./Tooltip.vue";
import { hasPermission } from "@/libraries/utils/hasPermission";

const { prefetchPatient } = usePatientPrefetch();

function prefetch() {
  prefetchPatient(props.patient.zis_number);
}

const props = defineProps<{
  patient: Person & {
    zis_number: number;
    labels?: Label[];
    deceased?: boolean;
  };
  format?: FormatName;
}>();

const formatter = computed(() => {
  if (props.format == FormatName.fullName) {
    return fullName;
  }

  return fullNameInitials;
});

const patientName = computed(() => formatter.value(props.patient));
</script>
