<template>
  <label
    class="relative box-border inline-flex cursor-pointer items-baseline gap-2 border ring-blue-500/50 transition duration-75 focus-within:z-20 focus-within:border-blue-600 focus-within:ring-3 focus:z-10 focus:outline-hidden"
    :class="[
      checked
        ? 'z-10 border-blue-500 bg-blue-500 text-white'
        : 'border-border-secondary bg-background-secondary text-text-secondary hover:bg-background-tertiary hover:shadow-inner',
      disabled ? 'cursor-default opacity-50' : 'hover:shadow-inner',
      collapse
        ? [
            'first:rounded-tl first:rounded-tr sm:first:rounded-tr-none sm:first:rounded-bl',
            'last:rounded-br last:rounded-bl sm:last:rounded-tr sm:last:rounded-bl-none',
            '-mt-px justify-center first:mt-0 sm:mt-0 sm:-ml-px sm:justify-start sm:first:ml-0',
          ]
        : '-ml-px first:ml-0 first:rounded-l last:rounded-r',
      getSizeClass(),
      textSize,
    ]"
  >
    <input
      class="sr-only m-0 box-border"
      v-model="modelValue"
      type="radio"
      :name="name"
      :value="optionValue"
      :disabled="disabled"
    />
    <slot></slot>
    <i
      v-if="icon"
      :class="[icon, iconPosition === 'right' ? 'order-last' : 'order-first']"
    ></i>
  </label>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue";
import { TextSizes } from "@/libraries/UI/text";

const modelValue = defineModel<T>({ required: true });

const props = withDefaults(
  defineProps<{
    optionValue: T;
    icon?: string;
    iconPosition?: "left" | "right";
    disabled?: boolean;
    name?: string;
    collapse?: boolean;
    size?: "md" | "sm" | "xs";
    textSize?: TextSizes;
  }>(),
  { textSize: "text-sm" },
);

const checked = computed(() => {
  return props.optionValue === modelValue.value;
});

function getSizeClass() {
  switch (props.size) {
    case "xs":
      return "px-2 py-1";
    case "sm":
      return "px-3 py-1.5";
    case "md":
    default:
      return "px-4 py-2 font-medium";
  }
}
</script>
