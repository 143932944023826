<template>
  <div
    :class="[
      'bg-background-card box-content sm:rounded-sm',
      shadow == 'never' ? 'border-border-card border' : '',
      shadow == 'always'
        ? 'dark:shadow-l shadow-sm sm:shadow-lg dark:shadow-zinc-950'
        : '',
      overflows ? 'overflow-visible' : 'overflow-hidden',
      grow ? 'h-full w-full grow' : '',
      warning ? 'border border-red-500' : '',
    ]"
  >
    <div v-if="$slots.header && !externalHeader" :class="cardHeaderClass">
      <slot name="header"></slot>
    </div>
    <div v-if="showBody" :class="cardBodyClass">
      <slot></slot>
    </div>
    <div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";

const props = defineProps({
  shadow: {
    default: "always",
    type: String as PropType<"always" | "hover" | "never">,
  },
  bodyClass: {
    type: String,
  },
  headerClass: {
    type: String,
  },
  externalHeader: {
    default: false,
    type: Boolean,
  },
  overflows: {
    default: false,
    type: Boolean,
  },
  grow: {
    default: false,
    type: Boolean,
  },
  showBody: {
    default: true,
    type: Boolean,
  },
  warning: {
    default: false,
    type: Boolean,
  },
});

const cardBodyClass = computed(() => props.bodyClass ?? defaultBodyClass.value);
const cardHeaderClass = computed(
  () => props.headerClass ?? "border-b border-border-tertiary p-3 lg:p-5",
);

const defaultBodyClass = computed(() =>
  props.externalHeader ? "p-0" : "p-3 lg:p-5",
);
</script>
