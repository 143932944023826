<template>
  <select
    class="border-box border-border-secondary bg-background-card text-text-primary relative m-0 block appearance-none rounded-sm border py-2 pr-3 pl-3 shadow-xs ring-blue-500/50 transition duration-75 focus:border-blue-600 focus:ring-3 focus:outline-hidden"
    v-model="modelValue"
    @change="emit('change', $event)"
    @blur="emit('blur', $event)"
    @keyup="emit('keyup', $event)"
    ref="root"
    :class="[
      textSize,
      'border-box border-border-secondary bg-background-card relative m-0 block appearance-none rounded-sm border text-sm shadow-xs ring-blue-500/50 transition duration-75 focus:border-blue-600 focus:ring-3 focus:outline-hidden',
      getPadding(),
      error ? 'ring-3 ring-red-400 focus:border-red-500' : '',
      disabled
        ? 'bg-background-secondary text-text-quaternary cursor-not-allowed'
        : '',
      fitContent ? 'w-auto' : 'w-full',
    ]"
    :disabled="disabled"
  >
    <option v-if="placeholder" :value="undefined" disabled hidden>
      {{ placeholder }}
    </option>
    <option v-if="placeholder" :value="null" disabled hidden>
      {{ placeholder }}
    </option>
    <slot></slot>
  </select>
</template>

<script setup lang="ts" generic="T">
import { TextSizes } from "@/libraries/UI/text";
import { useTemplateRef } from "vue";

const root = useTemplateRef("root");

const modelValue = defineModel<T | undefined>("modelValue", { required: true });

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    icon?: string;
    error?: boolean;
    disabled?: boolean;
    fitContent?: boolean;
    textSize?: TextSizes;
    size?: "md" | "sm";
  }>(),
  {
    size: "md",
    fitContent: false,
    textSize: "text-sm",
  },
);

const emit = defineEmits<{
  (e: "change", val: Event): void;
  (e: "blur", val: FocusEvent): void;
  (e: "keyup", val: KeyboardEvent): void;
  (e: "update:modelValue", val?: T): void;
}>();

function getPadding(): string {
  switch (props.size) {
    case "md":
      return "py-2 pl-3 pr-3";
    case "sm":
      return "py-1 pl-3 pr-3";
  }
}

function blur() {
  root.value?.blur();
}
defineExpose({ blur });
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.dark select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}
</style>
