<template>
  <div class="border-border-secondary flex h-[calc(100vh-250px)] grow flex-col">
    <div
      class="border-border-secondary flex items-center justify-start gap-2 border-b-[1px]"
    >
      <flux-button
        class="text-text-primary! p-0! md:hidden"
        v-if="activeItem"
        @click="activeItem = undefined"
        icon="fa fa-arrow-left"
        type="text"
      />
      <slot name="title">
        <p class="text-lg font-semibold md:text-2xl">{{ title }}</p>
      </slot>
    </div>
    <div class="h-full grow">
      <div class="flex h-full">
        <div
          class="border-border-secondary h-full grow overflow-y-auto px-4 md:max-w-[33%] md:min-w-[33%] md:border-r-[1px]"
          :class="activeItem ? 'hidden md:block' : ''"
        >
          <ul
            class="mt-4 ml-0 grid grid-cols-[3fr_1fr_max-content] gap-y-4 pl-0"
            v-if="items"
          >
            <li
              class="contents cursor-pointer"
              v-for="(item, index) in items"
              :key="index"
              @click="select(item)"
              :class="activeItem === item ? 'active' : ''"
            >
              <slot name="item" :item="item" :activeItem="activeItem"></slot>
            </li>
          </ul>
        </div>
        <div
          class="flex h-full w-full grow flex-col overflow-y-auto"
          v-if="activeItem"
        >
          <slot name="activeTitel" :item="activeItem"></slot>
          <div class="grow overflow-y-auto md:ml-8">
            <slot name="activeItem" :item="activeItem"> </slot>
          </div>
          <slot name="activeFooter" :item="activeItem"></slot>
        </div>
        <div
          class="hidden h-full w-full flex-col items-center justify-center gap-y-4 md:flex"
          v-else
        >
          <slot name="none-selected">
            <img
              class="w-full max-w-sm"
              src="../../assets/images/empty_inbox.svg"
            />
            <p class="text-text-quaternary text-center">
              Geen berichten geselecteerd
            </p>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup generic="T">
import { ref } from "vue";

defineProps<{
  items: T[] | undefined;
  title?: string;
}>();

const activeItem = ref<T>();

const select = (item: T) => {
  if (activeItem.value === item) {
    activeItem.value = undefined;
    return;
  }
  activeItem.value = item;
};
</script>
