<template>
  <div class="wrapper m-0 flex min-h-screen flex-row">
    <SidebarComponent />
    <Notifications />
    <Confirm
      :visible="visible"
      :onConfirm="props.onConfirm"
      :onCancel="props.onCancel"
      :confirmButtonText="props.confirmButtonText"
      :cancelButtonText="props.cancelButtonText"
      :canCancel="props.canCancel"
      :customHeader="props.customHeader"
      :customBody="props.customBody"
      :type="props.type"
    />
    <div class="safe-padding relative flex grow flex-col">
      <HeaderComponent />

      <div class="flex grow flex-col pt-5 md:px-5 md:pb-5">
        <OldBrowserAlert v-if="hasOldBrowser" />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import HeaderComponent from "./Header.vue";
import OldBrowserAlert from "./OldBrowserAlert.vue";
import SidebarComponent from "./Sidebar.vue";
import Notifications from "./ui/Notifications.vue";
import Confirm from "./ui/Confirm.vue";
import { useConfirm } from "@/composables/confirm";

const frequentlyUsedJavascriptFeatures = [
  "crypto" in window && "randomUUID" in window.crypto,
  "structuredClone" in window,
  typeof Array.prototype.at === "function",
  "toSorted" in Array.prototype,
];

const { visible, props } = useConfirm();

const hasOldBrowser = frequentlyUsedJavascriptFeatures.some(
  (hasFeature) => hasFeature === false,
);
</script>
