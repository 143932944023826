<template>
  <teleport to="body">
    <div
      class="fixed top-0 right-0 left-0 z-51 m-4 ml-auto flex max-w-md flex-col"
    >
      <template v-for="[uuid, notification] in last5notifications" :key="uuid">
        <Notification
          v-if="typeof notification.message === 'string'"
          :uuid="uuid"
          :type="notification.type"
          :message="notification.message"
          :title="notification.title"
          :showClose="notification.showClose"
          :duration="notification.duration"
          :action="notification.action"
        />
        <Notification
          v-else
          :uuid="uuid"
          :type="notification.type"
          :customComponent="toRaw(notification.message.component)"
          :customComponentProps="notification.message.props"
          :title="notification.title"
          :showClose="notification.showClose"
          :duration="notification.duration"
          :action="notification.action"
        />
      </template>
      <div class="z-51 mt-4 self-start" v-if="stackCount > 3">
        <div
          class="rounded-full bg-rose-500 px-3 py-0.5 text-xs font-bold text-white shadow-sm"
        >
          {{ stackCount }} meldingen
          <clickable-icon
            icon="fa-times text-white! font-medium!"
            @click="closeAll"
            content="Sluit alle meldingen"
          ></clickable-icon>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { computed, toRaw } from "vue";
import { useNotify } from "@/composables/notify";
import Notification from "@/components/ui/Notification.vue";

const { notifications } = useNotify();
const stackCount = computed(() => notifications.value.size);

async function closeAll() {
  const entries = notifications.value.entries();
  const rest = Array.from(entries).reverse().slice(5).reverse();
  for (const [uuid] of rest) {
    notifications.value.delete(uuid);
  }
  for (const [uuid] of last5notifications.value) {
    notifications.value.delete(uuid);
    await new Promise((resolve) => setTimeout(resolve, 50));
  }
}

const last5notifications = computed(() => {
  const entries = notifications.value.entries();
  return Array.from(entries).reverse().slice(0, 5).reverse();
});
</script>
