import { mergeAttributes, Node } from "@tiptap/vue-3";

type LegalExportOptions = object;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    legalExport: {
      /**
       * Add an image
       */
      setLegalExport: () => ReturnType;
    };
  }
}

export const LegalExport = Node.create<LegalExportOptions>({
  name: "legalExport",
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        class:
          "ring-1 ring-slate-100 bg-slate-0 px-3 py-4 rounded-sm w-full box-border block ProseMirror-LegalExport text-sky-400",
      }),
      "Behandeltraject Export",
    ];
  },
  parseHTML() {
    return [
      {
        tag: "span.ProseMirror-LegalExport",
      },
    ];
  },
  addAttributes() {
    return {
      field: "default",
    };
  },
  inline: true,

  group: "inline",

  addCommands() {
    return {
      setLegalExport:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: "legalExport",
          });
        },
    };
  },
});
