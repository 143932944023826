<template>
  <flux-form class="w-full" :submitOnEnter="true">
    <flux-input-box class="col-span-3 w-full" label="">
      <div class="rounded-lg p-3">
        <div
          class="bg-background-primary flex w-full items-stretch justify-between rounded-sm rounded-b-md"
        >
          <input
            class="text-m border-border-primary bg-background-primary text-text-primary mr-5 w-full rounded-md border p-2 outline-hidden"
            v-model="taskForm.title"
            type="text"
            :placeholder="$t('task.name_placeholder')"
            :maxlength="255"
          />
          <PlainDateInput v-model:model-value="taskForm.due_date" />
        </div>

        <div class="flex w-full">
          <textarea
            class="border-border-primary bg-background-primary text-text-primary my-3 flex h-full min-h-12 w-full resize-none overflow-y-auto rounded-sm border p-3 text-sm outline-hidden"
            v-model="taskForm.description"
            @input="autoGrow($event)"
            ref="textarea"
            :placeholder="$t('task.description_placeholder')"
            @focus="autoGrow($event)"
          ></textarea>
        </div>

        <div
          class="bg-background-primary inset-x-px bottom-px w-full rounded-b-md"
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <flux-dropdown
                class="w-100"
                v-model="taskForm.status"
                direction="right"
                :label="$t('task.status.' + taskForm.status)"
                :fullWidth="true"
              >
                <flux-dropdown-item
                  v-for="status in taskStatuses"
                  :key="status"
                  label="Status"
                  :value="status"
                  @click="() => (taskForm.status = status)"
                >
                  {{ $t("task.status." + status) }}
                </flux-dropdown-item>
              </flux-dropdown>
            </div>

            <div class="flex items-center gap-x-2">
              <UserTypeAhead
                v-model:modelValue="userInput"
                @select="(value) => selectUser(value)"
                placeholder="Behandelaar"
              />
              <PatientTypeAheadComponent
                class="w-full"
                v-model="patientInput"
                icon="far fa-search"
                :typeahead="patientTypeahead"
                placeholder="Patient"
                @select="(value) => selectPatient(value)"
              ></PatientTypeAheadComponent>
            </div>
          </div>
        </div>
      </div>
    </flux-input-box>
    <div class="flex justify-end gap-2 p-3">
      <flux-button class="mt-4 mb-2" @click="$emit('close')">{{
        $t("general.cancel")
      }}</flux-button>
      <flux-button class="mt-4 mb-2" type="primary" @click="save">{{
        taskForm?.id ? $t("general.update") : $t("general.create")
      }}</flux-button>
    </div>
  </flux-form>
</template>
<script setup lang="ts">
import { fullName } from "@/models/Person";
import { onMounted, PropType, ref } from "vue";
import PatientTypeAheadComponent from "@/components/ui/Input/PatientTypeAhead.vue";
import { PatientRepositoryUsingApi } from "@/libraries/repositories/patientRepositoryUsingApi";
import { Task, TaskAssignee, TaskPatient, taskStatuses } from "@/models/Task";
import { useNotify } from "@/composables/notify";
import { $t } from "@/libraries/i18n";
import UserTypeAhead from "@/components/ui/Input/UserTypeAhead.vue";
import { useUpdateTask } from "@/composables/myTasks";
import PlainDateInput from "../ui/PlainDateInput.vue";

const { notify } = useNotify();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "update"): void;
}>();

const props = defineProps({
  task: {
    type: Object as PropType<Task>,
    required: true,
  },
});

const patientRepositoryUsingApi = new PatientRepositoryUsingApi();

const taskForm = ref<Task>({ ...props.task });
const userInput = ref("");
const patientInput = ref("");

onMounted(() => {
  if (taskForm.value.patient) {
    fullName(taskForm.value.patient);
    selectPatient(taskForm.value.patient);
  }
  if (taskForm.value.assignee) {
    fullName(taskForm.value.assignee);
    selectUser(taskForm.value.assignee);
  }
});

const {
  mutateAsync: saveTask,
  reset,
  isSuccess,
  invalidate: invalidateTasks,
} = useUpdateTask();

async function save() {
  await saveTask(taskForm.value);

  if (isSuccess.value) {
    notify({
      message: $t("task.update.success"),
      type: "success",
    });
  } else {
    notify({
      message: $t("task.update.error"),
      type: "error",
    });
  }
  reset();
  invalidateTasks();
  emit("close");
}

function selectUser(user: TaskAssignee) {
  taskForm.value.assignee_id = user.id;
  taskForm.value.assignee = user;
  userInput.value = fullName(user);
}

function selectPatient(patient: TaskPatient) {
  taskForm.value.patient_zis_number = patient.zis_number;
  patientInput.value = fullName(patient);
}

function autoGrow(event: Event | null) {
  if (!event) {
    return;
  }
  if (!(event.target instanceof HTMLTextAreaElement)) {
    return;
  }
  event.target.style.height = "5px";
  event.target.style.height = event.target.scrollHeight - 75 + "px";
}

async function patientTypeahead() {
  return await patientRepositoryUsingApi.search(patientInput.value);
}
</script>
